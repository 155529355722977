<template>
  <div
    ref="user-widget"
    class="user-widget"
  >
    <div
      class="user-widget__view"
      @click="toggleDropdownVisibility"
    >
      <slot></slot>
    </div>
    <div
      v-if="isDropdownVisible"
      class="user-widget__dropdown"
    >
      <div class="user-widget__header">
        <div class="user-widget__info">
          <div class="user-widget__avatar">
            <img v-if="photo" :src="photo" />
            <i v-else class="bx bx-user" />
          </div>
          <div class="user-widget__personal-data">
            <p class="user-widget__name">{{ name }}</p>
            <p class="user-widget__position">{{ position }}, {{ department }}</p>
          </div>
        </div>
        <img
          class="user-widget__logout"
          src="@/assets/images/icons/ic-exit.svg"
          @click="handleLogout"
        />
      </div>
      <router-link
        v-if="$hasAccess(['admin'])"
        to="/console-commands"
        class="user-widget__option"
      >
        Console Commands
      </router-link>
      <router-link
        v-if="$hasAccess(['admin'])"
        to="/widget-settings"
        class="user-widget__option"
      >
        Widget Settings
      </router-link>
      <div class="user-widget__apps">
        <apps-widget :token="$keycloak.token" :baseUrl="widgetBaseUrl" />
      </div>
    </div>
  </div>
</template>

<script>
import AppsWidget from './apps-widget.vue';

export default {
  components: { AppsWidget },
  props: {
    name: String,
    photo: String,
    position: String,
    department: String
  },
  data() {
    return {
      isDropdownVisible: false,
      widgetBaseUrl: process.env.VUE_APP_API_PATH
    }
  },
  methods: {
    toggleDropdownVisibility() {
      this.isDropdownVisible = !this.isDropdownVisible;
    },
    handleClick(event) {
      if (!this.$refs['user-widget'].contains(event.target)) {
        this.isDropdownVisible = false;
      }
    },
    handleLogout() {
      this.$emit('on-logout');
    }
  },
  mounted() {
    window.addEventListener('click', this.handleClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleClick);
  }
};
</script>

<style lang="scss" scoped>
.user-widget {
  z-index: 12;
  color: #000;
  position: relative;
  font-family: "Nunito", sans-serif;

  &__view {
    cursor: pointer;
  }

  &__name {
    margin: 0px;
    font-size: 16px;
    font-weight: bold;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__position {
    margin: 0;
    font-size: 12px;
  }

  &__avatar {
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    background: #ededed;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    i {
      font-size: 26px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__header {
    display: flex;
    margin-bottom: 11px;
    padding-bottom: 12px;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #F1F1F1;
  }

  &__info {
    gap: 10px;
    display: grid;
    grid-template-columns: 40px 215px;
  }

  &__logout {
    width: 16px;
    cursor: pointer;
    margin-top: 7px;
  }

  &__option {
    color: #000;
    display: block;
    cursor: pointer;
    font-size: 12px;
  }

  &__option + &__option {
    margin-top: 10px;
  }

  &__dropdown {
    width: 336px;
    border-radius: 24px;
    background: #fff;
    top: 50px;
    right: 0px;
    padding: 20px;
    position: absolute;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, .12);

    @media (max-width: 450px) {
      width: 300px;
    }
  }

  &__apps {
    margin-top: 28px;
  }
}

</style>