<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="time-range-desktop row">
          <div class="col-md-10">
            <div class="card-body__title">
              <div class="time-range-desktop__header">
                <div class="d-flex position-relative">
                  <h2 class="font-size-18 pb-3">Daily Check In</h2>
                  <a href="/about/regulations" target='_blank'>
                    <i>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 6.75H9.75V5.25H8.25M9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM9 1.5C8.01509 1.5 7.03982 1.69399 6.12987 2.0709C5.21993 2.44781 4.39314 3.00026 3.6967 3.6967C2.29018 5.10322 1.5 7.01088 1.5 9C1.5 10.9891 2.29018 12.8968 3.6967 14.3033C4.39314 14.9997 5.21993 15.5522 6.12987 15.9291C7.03982 16.306 8.01509 16.5 9 16.5C10.9891 16.5 12.8968 15.7098 14.3033 14.3033C15.7098 12.8968 16.5 10.9891 16.5 9C16.5 8.01509 16.306 7.03982 15.9291 6.12987C15.5522 5.21993 14.9997 4.39314 14.3033 3.6967C13.6069 3.00026 12.7801 2.44781 11.8701 2.0709C10.9602 1.69399 9.98491 1.5 9 1.5ZM8.25 12.75H9.75V8.25H8.25V12.75Z" fill="#4668DD"/>
                      </svg>
                    </i>
                  </a>
                </div>
              </div>
              <p v-if="!isLocalSameUzbTime">Your local time – {{ $moment(localTime).format('LL') + ' ' + $moment(localTime).format('HH:mm') }}</p>
              <h5>UZT Time – {{ $moment(uzbTime).format('LL') + ' ' + $moment(uzbTime).format('HH:mm') }}</h5>
            </div>
          </div>
            <div class="d-flex col-2 align-items-start justify-content-end">
              <div class="cursor-pointer" @click="showSettings()">
                <i v-b-tooltip.hover title="Settings">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                    <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/>
                    <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/>
                  </svg>
                </i>
              </div>
            </div>
          <div class="col-md-12">
            <div
              class="time-range"
              :class="{ 'time-range_checked': checkInfo.isCheck }"
            >
              <div
                class="time-range__container"
                ref="timeRangeContainer"
              >
                <div class="time-range__tooltip">
                  <span></span>
                  <div></div>
                </div>
                <div class="time-range__prefix">
                  <div class="time-range__prefix__lines">
                    <div class="line"></div>
                    <div class="line"></div>
                  </div>
                  <div class="time-range__prefix__block" />
                </div>
                <div class="time-menu time-menu__up line-disabled" v-if="!isLocalSameUzbTime">
                  <div class="time-menu__item" v-for="(item, idx) in options.end_time" :key="item">
                    <div class="lines">
                      <div class="lines__strong"></div>
                      <div class="lines__ordinary">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                      </div>
                    </div>
                    <span class="strong">
          {{ getLocalHours(idx + (timeLocaleStart + options.start_time)) }}:00
        </span>
                  </div>
                </div>
                <div
                    class="time-menu time-menu__up"
                >
                  <div
                      class="time-menu__item"
                      v-for="(item, idx) in options.end_time"
                      :key="item"
                      :class="[getCurrentClassName(item - 1)]"
                      v-b-tooltip.hover="{
                          html: true,
                          customClass: 'mandatory-tooltip',
                          title: getMandatoryTitle(getCurrentClassName(item - 1)),

                      }"
                  >
                    <div class="lines">
                      <div class="lines__strong"></div>
                      <div class="lines__ordinary">
                        <div class="line"></div>
                        <div class="line"></div>
                        <div class="line"></div>
                      </div>
                    </div>
                    <span class="strong">
          {{ getLocalHours(idx +  options.start_time) }}:00
        </span>
                  </div>
                </div>
                <div
                    class="veeno-container"
                    @mousemove="handleMouseMove"
                    @mouseleave="handleMouseLeave"
                >
                  <veeno
                    v-if="isShowTimeLine"
                    v-load="loadTimeRange"
                    ref="veeno"
                    :range="{'min': options.start_time, 'max': maxLimit }"
                    :step = "1"
                    :handles="handlesData"
                    :disabled="checkInfo.isCheck"
                    :connect="connects"
                    behaviour="tap-drag"
                    @change="timeRangeChange"
                    @slide="moveAttributes($event)"
                  />
                </div>
                <RedTimeArrow
                    v-if="containerWidth > 0 && uzbTime"
                    :containerWidth="containerWidth"
                    :time="uzbTime"
                    :config="redArrowConfig"
                />
              </div>
              <div class="time-range__detail">
                <div class="row">
                  <div class="col-md-6">
                    <b-form-checkbox
                      id="checkbox-1"
                      name="checkbox-1"
                      v-model="checkInfo.isOfficeEmployee"
                      :unchecked-value="false"
                    >
                      I work in the office today
                    </b-form-checkbox>
                    <b-form-checkbox
                      id="checkbox-2"
                      name="checkbox-2"
                      v-model="checkInfo.isLunch"
                      :unchecked-value="false"
                      class="d-inline-block"
                      v-b-tooltip.hover
                      :title="lunchTooltipText"
                      :disabled="!checkInfo.isOfficeEmployee || checkInfo.isCheck || !isLunchCheckboxEnabled"
                    >
                      Reserve lunch
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-6">
                    <div class="d-flex justify-content-end">
                      <div class="d-flex flex-column mr-4 align-items-end">
                        <p class="mb-0"><strong>Total online today:</strong> <span :class="parseInt(times) < requiredWorkHours ? 'text-danger' : 'text-success'">{{ times }}</span></p>
                        <p
                          v-if="getPenaltyFromMandatoryHours"
                          class="mb-0 text-danger"
                        >
                          Penalty {{ getPenaltyFromMandatoryHours }} hour
                        </p>
                        <p :class="parseInt(times) < requiredWorkHours ? 'text-danger' : 'text-secondary'">
                          Minimum {{ requiredWorkHours }} hours required
                        </p>
                      </div>
                      <div>
                        <button
                            class="btn w-100"
                            :class="[
                            checkInfo.isCheck ? 'btn-outline-success' : 'btn-primary',
                            (parseInt(times) < requiredWorkHours && !checkInfo.isCheck || getPenaltyFromMandatoryHours > 0) ? 'btn-danger' : ''
                          ]"
                            :disabled="checkInfo.isCheck" @click.prevent="checkTime(getPenaltyFromMandatoryHours)"
                        >
                          <div v-if="!checkInfo.isCheck">
                            <i>
                              <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.50001 16.42L0.290009 10.21L3.12001 7.38L6.50001 10.77L16.38 0.880005L19.21 3.71L6.50001 16.42Z" fill="white"/>
                              </svg>
                            </i>
                            Check in

                          </div>
                          <div style="color: #2D3338; font-weight: 700;" v-else>
                            Checked in at {{ $moment(options.checked_at ? options.checked_at : checkInfo.checked_at).format('HH:mm') }}
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="time-range-mobile">
          <div class="d-flex flex-column">
            <img src="@/assets/images/mobile-mask.svg" alt="">
            <p>
              Temporarily, check in is not possible on the mobile devices
            </p>
          </div>
          <div class="d-flex p-0 justify-content-center">
            <b-button
              v-if="isMobileReserveButtonVisible"
              variant="primary"
              class="font-size-14"
              @click="reserveLunch"
            >
              Reserve Lunch
            </b-button>
            <b
              v-if="checkInfo.isLunchButton"
              class="font-size-14 text-dark mt-4"
            >
              Lunch has been reserved
            </b>
          </div>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-example" hide-footer :modalClass="'modal-confirm'" :centered="true">
      <div class="d-block" style="padding-bottom: 20px">
        <h3>Please confirm</h3>
        <p>Are you sure today you are ready to work just {{ times }}</p>
        <span>Required minimum 0{{ requiredWorkHours }}:00</span>
        <p class="mt-1">Penalties will be applied</p>
      </div>
      <div class="d-flex justify-content-end">
        <b-button class="btn btn-success mr-2" @click.prevent="$bvModal.hide('bv-modal-example')">Cancel and change timeline</b-button>
        <b-button class="btn btn-danger" @click.prevent="acceptCheckIn">Confirm</b-button>
      </div>
    </b-modal>
    <b-modal id="bv-modal-settings" hide-footer :modalClass="'modal-settings'" :centered="true" title="Settings">
      <div class="d-flex align-items-center justify-content-center">
        <div class="font-size-14" style="text-align: right"><strong>Notification Time</strong><span style="position: relative">
                    <i v-b-tooltip.hover title="This parameter allows you to set the time (local time) when the system will send you a notification to check in." style="position: absolute; top: -8px; left: 2px; z-index: 10">
                      <svg width="15" height="15" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8.25 6.75H9.75V5.25H8.25M9 15C5.6925 15 3 12.3075 3 9C3 5.6925 5.6925 3 9 3C12.3075 3 15 5.6925 15 9C15 12.3075 12.3075 15 9 15ZM9 1.5C8.01509 1.5 7.03982 1.69399 6.12987 2.0709C5.21993 2.44781 4.39314 3.00026 3.6967 3.6967C2.29018 5.10322 1.5 7.01088 1.5 9C1.5 10.9891 2.29018 12.8968 3.6967 14.3033C4.39314 14.9997 5.21993 15.5522 6.12987 15.9291C7.03982 16.306 8.01509 16.5 9 16.5C10.9891 16.5 12.8968 15.7098 14.3033 14.3033C15.7098 12.8968 16.5 10.9891 16.5 9C16.5 8.01509 16.306 7.03982 15.9291 6.12987C15.5522 5.21993 14.9997 4.39314 14.3033 3.6967C13.6069 3.00026 12.7801 2.44781 11.8701 2.0709C10.9602 1.69399 9.98491 1.5 9 1.5ZM8.25 12.75H9.75V8.25H8.25V12.75Z" fill="#4668DD"/>
                      </svg>
                    </i>
                    </span></div>
        <date-picker
            id="day-start"
            class="col-4"
            style="margin-left: 10px"
            v-model="checkInfo.notificationTime"
            type="time"
            value-type="format"
            format="HH:mm"
            :minute-step="30"
            :clearable="false"
        />
        <div class="p-0">
          <b-button
              type="submit"
              variant="primary"
              style="min-width: auto"
              @click="addNotification(checkInfo.notificationTime)"
          >
            Change
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import veeno from 'veeno';
import 'nouislider/distribute/nouislider.min.css';
import '@/assets/style/_timerange.scss'
import RedTimeArrow from "@/components/profile/red-time-arrow.vue";
import TimeIntervalMixin from "@/mixins/time-interval-mixin";
import { REMOTE_LOCATION_ID } from '@/utils/constants';

export default {
  name: "TimeRange",
  props: {
    options: {
      type: Object,
      default: () => {
        return {
          start_time: 0,
          end_time: 24,
          is_check: false,
          checked_at: null,
          location_checked: null,
          is_lunch: false,
          prev: null,
          mandatoryWorkHours: [14, 15],
          data: {
            handlesData: [],
            connects: []
          },
          lunchTime: {
            type: Array,
            default: () => [13, 14]
          },
          limitOfficeTime: {
            type: Object,
            default: () => {
              return {
                from: 7,
                to: 21
              }
            }
          }
        }
      }
    },
    location: {
      type: Object,
      default: () => {}
    },
    locations: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      times: null,
      tooltip: null,
      localTime: this.$moment(),
      timeDiff: null,
      timeLocaleStart: null,
      value: null,
      handlesData: [],
      connects: [],
      isShowTimeLine: false,
      loadTimeRange: false,
      maxLimit: 96,
      disabledCount: 0,
      disabledRow: null,
      penalty: 0,
      checkInfo: {
        isCheck: false,
        checked_at: null,
        isOfficeEmployee: false,
        isLunch: false,
        isLunchButton: false,
        notificationTime: null
      },
      requiredWorkHours: 7,
      mandatoryTitle: null,
      stateTimePanel: {
        required: 'required-time',
        nonRequired: 'non-working-time',
        lunchTime: 'lunch-time'
      },
      containerWidth: null,
      redArrowConfig: {
        style: {
          bottom: '-15px'
        },
        excludePaddingPx: 3
      }
    }
  },
  mixins: [TimeIntervalMixin],
  components: {
    veeno,
    RedTimeArrow
  },
  watch: {
    'isShowTimeLine' (val) {
      if (val) {
        this.$nextTick(() => {
          this.addHoversEffect()
          this.addDisabledRow(this.disabledCount)
          this.getCurrentTime()
          this.addCurrentTimeAndCloseIcon()
          if (!this.checkInfo.isCheck && !this.checkInfo.isOfficeEmployee) {
            this.constructPlus()
          }
        })
      }
    },
    'options.is_check': {
      handler(val) {
        if (val) {
          this.checkInfo.checked_at = this.checked_at
          this.checkInfo.isCheck = true
          this.constructData(true).then(() => {
            this.isShowTimeLine = true
          })
        } else {
          this.constructData(false).then(() => {
            this.isShowTimeLine = true
          })
        }
      },
      immediate: true
    },
    'checkInfo.isOfficeEmployee'(val) {
      if (val && !this.checkInfo.isCheck) {
        this.constructHandleDataForOffice()
      }
      if (!val && !this.checkInfo.isCheck) {
        this.checkInfo.isLunch = false
      }
      if (this.checkInfo.isCheck) {
        this.changeLocation(val);
      }
    },
  },
  computed: {
    loading() {
      return this.$store.state.profile.loading
    },
    isLocalSameUzbTime() {
      return this.$moment(this.localTime).isSame(this.uzbTime, 'hours')
    },
    isLunchCheckboxEnabled() {
      const beforeDateTime = `${this.$moment().format('YYYY-MM-DD')} 09:30`;
      return this.$moment(this.uzbTime).isBefore(beforeDateTime);
    },
    getPenaltyFromMandatoryHours() {
      const mandatoryWorkTimeFrom = this.getTimePointForTimeline(this.$moment(`${this.$moment().format('YYYY-MM-DD')} ${this.options.mandatoryWorkHours[0]}:00`).format('HH:mm'))
      const mandatoryWorkTimeTo = this.getTimePointForTimeline(this.$moment(`${this.$moment().format('YYYY-MM-DD')} ${this.options.mandatoryWorkHours[1] + 1}:00`).format('HH:mm'))
      const a = parseInt(this.times) < this.requiredWorkHours ? Math.floor(this.requiredWorkHours - parseInt(this.times)) : 0
      const b = this.handlesData.reduce((acc, cur, index) => {
        if (index % 2) {
          for (let i = parseInt(this.handlesData[index - 1]); i < parseInt(this.handlesData[index]); i += 1) {
            for (let j = mandatoryWorkTimeFrom - this.disabledCount > 0 ? mandatoryWorkTimeFrom - this.disabledCount : 0; j < mandatoryWorkTimeTo - this.disabledCount; j += 1) {
              if (i === j) {
                acc += 1
              }
            }
          }
        }
        return acc
      }, 0)
      return a > Math.abs(Math.floor(b / 4) - this.options.mandatoryWorkHours.length) ?
          a : Math.abs(Math.floor(b / 4) - this.options.mandatoryWorkHours.length)
    },
    lunchTooltipText() {
      if (this.checkInfo.isOfficeEmployee && this.checkInfo.isCheck && this.isLunchCheckboxEnabled) {
        return 'In case if you have forgotten to reserve lunch, please contact office manager';
      }

      return 'You cannot reserve lunch after 09:30. Please make sure you have alternative lunch options.'
    },
    isMobileReserveButtonVisible() {
      if (this.checkInfo.isCheck) {
        return false;
      }
      return !this.checkInfo.isLunchButton && this.isLunchCheckboxEnabled;
    }
  },
  methods: {
    changeLocation(isInOffice) {
      this.$store.dispatch('profile/updateProfileLocation', {
        location_id: isInOffice
          ? this.locations.find(el => el.title.toLowerCase().includes('office'))?.id
          : REMOTE_LOCATION_ID,
      })
    },
    createTimeDivChild(isDurationsBlock) {
      const element = document.createElement('div');
      if (!isDurationsBlock) {
        element.style.display = 'flex'
        element.style.flexDirection = 'column'
        for (let i = 0; i < 2; i += 1) {
          const span = document.createElement('span')
          span.style.fontSize = i === 0 ? '10px' : '14px'
          element.appendChild(span)
        }
        element.className = 'time__value'
        return element
      }

      element.className = 'time__duration'
      return element
    },
    fillTimeValues(timeElement, index) {
      const [startDiv, finishDiv, durationDiv] = timeElement.children;
      const [startTime, finishTime] = this.getStartAndFinishTime(index);
      const [localStartDiv, currentStartDiv] = startDiv.getElementsByTagName('span')
      const [localFinishDiv, currentFinishDiv] = finishDiv.getElementsByTagName('span')
      const duration = this.getDiffTime(index);
      const diffHours = +duration.split(':')[0];
      durationDiv.textContent = duration;
      localStartDiv.textContent = !this.isLocalSameUzbTime ? this.getDiffTimeFromLocal(startTime) : ''
      currentStartDiv.textContent = startTime
      localFinishDiv.textContent = !this.isLocalSameUzbTime ? this.getDiffTimeFromLocal(finishTime) : ''
      currentFinishDiv.textContent = finishTime
      localFinishDiv.style.textAlign = 'right'
      finishDiv.style.display = diffHours < 2 ? 'none' : 'flex';
    },
    getMandatoryTitle(className) {
      switch (className) {
        case this.stateTimePanel.required:
          return `<p>Mandatory work time </br>${this.options.mandatoryWorkHours[0]}-${this.options.mandatoryWorkHours[this.options.mandatoryWorkHours.length - 1] + 1} UZT Time</p>`
        case this.stateTimePanel.lunchTime:
          return '<p>Lunch in Tashkent</p>'
        case this.stateTimePanel.nonRequired:
          return '<p>Tashkent office </br>is closed</p>'
      }
    },
    getCurrentClassName(hour) {
      if (this.options.mandatoryWorkHours.some(el => +el === hour)) {
        return this.stateTimePanel.required
      }
      if (hour === +this.options.lunchTime[0]) {
        return this.stateTimePanel.lunchTime
      }
      if (hour < this.options.limitOfficeTime.from || hour >= this.options.limitOfficeTime.to) {
        return this.stateTimePanel.nonRequired
      }

      return ''
    },
    fillTimeDivAttributes(connectDiv, timeDiv, offset) {
      const { width } = connectDiv.getBoundingClientRect();
      const leftDirection = this.getTranslateX(connectDiv);
      timeDiv.style.width = width + 'px';
      timeDiv.style.transform = `translateX(${leftDirection + offset}px)`;
    },
    getDiffTimeFromLocal(time) {
      const curDate = this.$moment().format('YYYY-MM-DD')
      const curDateTime = this.$moment(`${curDate} ${time}`, 'YYYY-MM-DD HH:mm')
      return curDateTime.add(this.timeLocaleStart, 'hours').format('HH.mm')
    },
    fillAddIconAttribute(icon) {
      if (!icon) return;
      const disabledRow = document.getElementsByClassName('veeno__disabled-row')[0];
      const connects = this.$refs['veeno'].$el.getElementsByClassName('noUi-connect');
      const connect = [...connects].at(-1);
      const { width } = connect.getBoundingClientRect();
      const leftDirection = this.getTranslateX(connect);

      icon.style.transform = `translateX(${disabledRow.offsetWidth + leftDirection + width + 20}px)`;
    },
    fillCloseButtonAttributes(button, connect, offset) {
      const { width } = connect.getBoundingClientRect();
      button.style.transform = `translateX(${offset + this.getTranslateX(connect) + width - 17}px)`;
    },
    async addNotification(notificationTime) {
      const data = {
        'notification_time' : this.$moment(notificationTime, 'HH:mm').add(this.timeLocaleStart * -1, 'hours').format('HH:mm')
      };
      await this.$store.dispatch('profile/changeEmployeeSettings', this.$convertToFormData({...data, _method: 'POST'}));
    },
    handleMouseMove(event) {
      const [timeRangeContainer] = document.getElementsByClassName('time-range__container')
      const [offsetBlockPrefix] = document.getElementsByClassName('time-range__prefix')
      const pixelsInOneMeasurment = timeRangeContainer.offsetWidth / 99;
      const roulette = [];
      for (let i = 1; i <= 98; i += 1) {
        roulette.push(i * pixelsInOneMeasurment);
      }
      const rect = event.currentTarget.getBoundingClientRect();
      const offset = event.pageX - (rect.left - offsetBlockPrefix.offsetWidth);
      const measurment = roulette.findIndex((item, index) => {
        if (index === 0) {
          return (offset >= 0 && offset <= item);
        }
        return (offset >= roulette[index - 1] && offset <= item)
      });
      const time = this.getTime((measurment < 3 ? 0 : (measurment - 3) * 15) / 60);
      if (this.tooltip) {
        const [localTimeBlock, uzbTimeBlock] = this.tooltip.children;
        const localTime = this.$moment(
          `${this.$moment().format('YYYY-MM-DD')} ${time}`, 'YYYY-MM-DD HH:mm'
        ).add(this.timeLocaleStart, 'hours').format('HH:mm')
        uzbTimeBlock.textContent = time;
        localTimeBlock.textContent = this.isLocalSameUzbTime ? '' : localTime
        this.tooltip.style.opacity = 1;
        this.tooltip.style.left = offset - (this.tooltip.offsetWidth / 2) + 'px';
      }
    },
    handleMouseLeave() {
      this.tooltip.style.opacity = 0;
    },
    handleWindowResize() {
      const connects = this.$refs['veeno'].$el.getElementsByClassName('noUi-connect');
      const [plus] = this.$refs['veeno'].$el.getElementsByClassName('noUi-connect__add');
      [...connects].forEach((_, index) => {
        this.moveObjects(index + 1);
      });
      this.containerWidth = this.$refs['timeRangeContainer']?.offsetWidth ?? 0
      this.fillAddIconAttribute(plus);
    },
    filterHandleData (data) {
      return data.reduce((prev, cur, idx) => {
        if (idx > 0) {
          prev.push(parseInt(cur) - parseInt(prev[idx - 1]) >= 4 ? parseInt(cur)+'.00' : (parseInt(prev[idx - 1]) + 4) + '.00')
        } else {
          prev.push(cur)
        }

        return prev
      }, [])
    },
    getCurrentTime() {
      let point = this.handlesData.reduce((prev, cur) => {
        return Math.ceil(parseInt(cur) - parseInt(prev))
      }, 0)
      this.times = this.getTime((point * 15) / 60)

      return new Promise((resolve) => {
        resolve(true)
      })
    },
    convertPointsToHours(points) {
      return (parseInt(points) + this.disabledCount) * 15 / 60;
    },
    converHoursToPoints(hours) {
      return (hours * 4) - this.disabledCount;
    },
    handleDataForOfficeWorker(values) {
      const newValues = [...values];

      const last = this.convertPointsToHours(newValues[newValues.length - 1]);
      const prevLast = this.convertPointsToHours(newValues[newValues.length - 2]);
      const officeCloseTime = this.options.limitOfficeTime.to;

      if (last > officeCloseTime) {
        newValues[newValues.length - 1] = `${this.converHoursToPoints(officeCloseTime)}.00`;
      }
      if ((last > officeCloseTime && last - prevLast <= 1) || (last >= officeCloseTime && prevLast >= officeCloseTime)) {
        newValues[newValues.length - 1] = `${this.converHoursToPoints(officeCloseTime)}.00`;
        newValues[newValues.length - 2] = `${this.converHoursToPoints(officeCloseTime - 1)}.00`;
      }

      return newValues;
    },
    timeRangeChange(ev){
      this.isShowTimeLine = false
      if (ev?.values && ev.values.length > 0) {
        const values = this.checkInfo.isOfficeEmployee ? this.handleDataForOfficeWorker(ev.values) : ev.values;
        this.handlesData = this.filterHandleData(values);
        this.getCurrentTime().then((res) => {
          if (res) {
            this.isShowTimeLine = true
            return this.$nextTick(() => {
              this.movePlusIcon()
            })
          }
        })
      }
    },
    moveAttributes(event) {
      this.handlesData = this.filterHandleData(event.values)
      this.moveObjects(event.handle)
      this.getCurrentTime()
      this.movePlusIcon()
    },
    moveObjects(idx){
      try {
        const connect = this.$refs['veeno'].$el.getElementsByClassName('noUi-connect');
        const disabledRow = document.getElementsByClassName('veeno__disabled-row')[0];

        if (idx === 0 || idx === 1) {
          const time = document.getElementsByClassName(`time-1`);
          const [greenLine] = document.getElementsByClassName(`green-line-1`);

          this.fillTimeValues(time[0], 0);
          this.fillTimeDivAttributes(connect[0], time[0], disabledRow?.offsetWidth || 0);
          this.fillObject({
            obj: greenLine,
            connect: connect[0],
            offset: disabledRow?.offsetWidth || 0
          })
        }
        if (idx === 2 || idx === 3) {
          const time = document.getElementsByClassName(`time-2`);
          const closeIcon = document.getElementsByClassName(`close-action__2`);
          const [greenLine] = document.getElementsByClassName(`green-line-2`);

          this.fillObject({
            obj: greenLine,
            connect: connect[1],
            offset: disabledRow?.offsetWidth || 0
          })
          this.fillTimeValues(time[0], 1);
          this.fillTimeDivAttributes(connect[1], time[0], disabledRow?.offsetWidth || 0);
          this.fillCloseButtonAttributes(closeIcon[0], connect[1], disabledRow?.offsetWidth || 0);
        }
        if (idx === 4 || idx === 5) {
          const time = document.getElementsByClassName(`time-3`);
          const closeIcon = document.getElementsByClassName(`close-action__3`);

          this.fillTimeValues(time[0], 2);
          this.fillTimeDivAttributes(connect[2], time[0], disabledRow?.offsetWidth || 0);
          this.fillCloseButtonAttributes(closeIcon[0], connect[2], disabledRow?.offsetWidth || 0);
        }
      } catch (e) {
        return e
      }
    },
    diffTime(timeA, timeB) {
      let getDate = (string) => new Date(0, 0,0, string.split(':')[0], string.split(':')[1]); //получение даты из строки (подставляются часы и минуты
      let different = (getDate(timeB) - getDate(timeA));

      let hours = Math.floor((different % 86400000) / 3600000);
      let minutes = Math.round(((different % 86400000) % 3600000) / 60000);

      return `${hours}:${minutes === 0 ? '00' : minutes}`
    },
    getStartAndFinishTime(index) {
      if (index === 0) {
        return [this.getTime(((parseInt(this.handlesData[index]) + this.disabledCount) * 15) / 60), this.getTime(((parseInt(this.handlesData[index + 1]) +  + this.disabledCount) * 15) / 60)];
      }
      if (index === 1) {
        return [this.getTime(((parseInt(this.handlesData[index + 1]) + this.disabledCount) * 15) / 60), this.getTime(((parseInt(this.handlesData[index + 2]) + this.disabledCount) * 15) / 60)];
      }
      if (index === 2) {
        return [this.getTime(((parseInt(this.handlesData[index + 2]) + this.disabledCount) * 15) / 60), this.getTime(((parseInt(this.handlesData[index + 3]) + this.disabledCount) * 15) / 60)];
      }
    },
    getDiffTime(index) {
      const resArr = this.getStartAndFinishTime(index);

      return this.diffTime(resArr[0], resArr[1])
    },
    getTranslateX(element) {
      const style = window.getComputedStyle(element);
      const matrix = new DOMMatrixReadOnly(style.transform);
      return matrix.m41;
    },
    fillObject({obj, connect, offset}) {
      const { width } = connect.getBoundingClientRect();
      const leftDirection = this.getTranslateX(connect);
      obj.style.width = width + 'px';
      obj.style.transform = `translateX(${leftDirection + offset}px)`;
    },
    addGreenLine(index) {
      const div = document.createElement('div')
      div.style.position = 'absolute'
      div.style.top = '-13px'
      div.style.left = 0
      div.style.height = '10px'
      div.style.zIndex = 7
      div.style.backgroundColor = '#009D71'
      div.classList.add(`green-line-${index}`)
      return div
    },
    addCurrentTimeAndCloseIcon() {
      const connect = this.$refs['veeno'].$el.getElementsByClassName('noUi-connect');
      const disabledRow = document.getElementsByClassName('veeno__disabled-row')[0];
      let iter = this.handlesData.length / 2
      for (let i = 0; i < iter; i += 1) {
        const div = document.createElement('div')
        const greenLine = this.addGreenLine(i + 1)
        if (i !== 0 && !this.checkInfo.isCheck && !this.checkInfo.isOfficeEmployee) {
          const closeButton = document.createElement('div');
          closeButton.classList.add('close-action', `close-action__${i + 1}`);
          closeButton.innerHTML = `
            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.732422" width="14" height="14" rx="7" fill="#2D3338"/>
            <path d="M5.23242 4.5L10.2324 9.5" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
            <path d="M10.2324 4.5L5.23242 9.5" stroke="white" stroke-width="1.2" stroke-linecap="round"/>
            </svg>
        `;
          closeButton.style.opacity = '0'
          this.fillCloseButtonAttributes(closeButton, connect[i], disabledRow?.offsetWidth || 0);
          closeButton.addEventListener('mousemove', () => {
            closeButton.style.opacity = 1
            closeButton.style.cursor = 'pointer'
          })
          closeButton.addEventListener('click', () => {
            this.deleteHandler(i)
          })
          this.$refs['veeno'].$el.appendChild(closeButton)
        }
        div.classList.add('time', `time-${i + 1}`);
        this.fillTimeDivAttributes(connect[i], div, disabledRow?.offsetWidth || 0);

        div.appendChild(this.createTimeDivChild());
        div.appendChild(this.createTimeDivChild());
        div.appendChild(this.createTimeDivChild(true));
        this.fillTimeValues(div, i);
        this.fillObject({
          obj: greenLine,
          connect: connect[i],
          offset:disabledRow?.offsetWidth || 0,
        })
        this.$refs['veeno'].$el.appendChild(div)
        this.$refs['veeno'].$el.appendChild(greenLine)
      }
    },
    deleteHandler(idx) {
      this.loadTimeRange = true
      this.isShowTimeLine = false
      this.handlesData.splice(idx + 1, 2)
      this.connects.splice(idx + 1, 2)
      this.$nextTick(() => {
        this.isShowTimeLine = true
        setTimeout(() => {
          this.loadTimeRange = false
        }, 500)
      })
    },
    movePlusIcon() {
      const [plus] = this.$refs['veeno'].$el.getElementsByClassName('noUi-connect__add');
      this.fillAddIconAttribute(plus);
    },
    getTime(time) {
      const res = String(time).split('.')
      if (res[0] === '24') {
        return '24:00'
      }
      switch (res[1]) {
        case '25':
          return `${res[0] + ':' + '15'}`
        case '5':
          return `${res[0] + ':' + '30'}`
        case '75':
          return `${res[0] + ':' + '45'}`
        case undefined:
          return `${res[0] + ':' +  '00'}`
      }
      return `${res[0] + ':' + res[1]}`
    },
    async addRange() {
      if (this.handlesData.length < 6) {
        this.loadTimeRange = true
        this.isShowTimeLine = false
        this.$nextTick(() => {
          this.handlesData.push(String(parseInt(this.handlesData[this.handlesData.length - 1]) + 4) + '.00', String(parseInt(this.handlesData[this.handlesData.length - 1]) + 8) + '.00')
          this.connects.push(true, false)
          this.isShowTimeLine = true
          setTimeout(() => {
            this.loadTimeRange = false
          }, 500)
        })
      }
    },
    getLocalHours(number) {
      let hours = number;
      if (number < 0) {
        const n = parseInt(String(number).substr(1));
        hours = 24 - n;
      }
      if (number > 23) {
        hours = number - 24;
      }
      return hours < 10 ? `0${hours}` : hours;
    },
    removePlus() {
      const plus = this.$refs['veeno'].$el.getElementsByClassName('noUi-connect__add')
      for (let i = 0; i < plus.length; i += 1) {
        plus[i].remove()
      }
    },
    removeCloseIcons() {
      const closeBtns = this.$refs['veeno'].$el.getElementsByClassName('close-action')
      for (let i = 0; i < closeBtns.length; i += 1) {
        closeBtns[i].remove()
      }
    },
    constructPlus() {
      try {
        if (this.$refs['veeno'].$el.getElementsByClassName('noUi-connect__add').length > 0) {
          this.$refs['veeno'].$el.getElementsByClassName('noUi-connect__add')[0].forEach(el => el.remove())
        }
      } catch (e) {
        return e
      }
      const connect = this.$refs['veeno'].$el.getElementsByClassName('noUi-connect')
      if (connect.length < 2) {
        const div = document.createElement('div')
        div.innerHTML = `
      <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 5C10 5.55228 9.55229 6 9 6H6V9C6 9.55229 5.55228 10 5 10C4.44772 10 4 9.55229 4 9V6H1C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H4V1C4 0.447715 4.44772 0 5 0C5.55228 0 6 0.447715 6 1V4H9C9.55229 4 10 4.44772 10 5Z" fill="black"/>
      </svg>
       `
        div.classList.add('noUi-connect__add');
        this.fillAddIconAttribute(div);
        div.addEventListener('click', () => {
          this.addRange()
        })
        this.$refs['veeno'].$el.appendChild(div)
      }
    },
    addDisabledLine() {
      const veeno = this.$refs['veeno'].$el
      const div = document.createElement('div')
      div.classList.add('veeno__disabled-line')
      for (let i = 0; i < 3; i += 1) {
        let div2 = document.createElement('div')
        div2.classList.add('line', `line-${i + 1}`)
        div.appendChild(div2)
      }
      if (this.checkInfo.isCheck) {
        veeno.classList.add('disabled')
      }
      veeno.appendChild(div)
    },
    addDisabledRow(maxTime) {
      const div = document.createElement('div')
      const noUiBase = this.$refs['veeno'].$el.getElementsByClassName('noUi-base')
      div.classList.add('veeno__disabled-row')
      div.style.width = ((maxTime * 100) / 96) + '%'
      if (maxTime > 0) {
        noUiBase[0].style.width = `calc(100% - ${(maxTime * 100) / 96}%)`
        this.$refs['veeno'].$el.appendChild(div)
      }
    },
    getTimePointForTimeline(time) {
      const timeSplit = time.split(':')
      let hours = parseInt(timeSplit[0])
      let minutes = parseInt(timeSplit[1]) === 0 ? 1 : parseInt(timeSplit[1])
      let result = 0

      if (minutes > 0 && minutes < 15) {
        result = (hours * 4)
      }
      if (minutes >= 15 && minutes < 30) {
        result = (hours * 4) + 1
      }
      if (minutes >= 30 && minutes < 45) {
        result = (hours * 4) + 2
      }
      if (minutes >= 45 && minutes <= 59) {
        result = (hours * 4) + 3
      }


      return result > 0 ? result : 96
    },
    getDisabledCount() {
      const uzbHour = +this.$moment(this.uzbTime).format('HH');
      const uzbTime = this.$moment(this.uzbTime).add(15, 'minutes').format('HH:mm');
      if (this.location?.title === 'office') {
        return this.getTimePointForTimeline(uzbHour < 7 ? '7:00' : uzbTime);
      }
      return this.getTimePointForTimeline(uzbTime);
    },
    getCurrentLocation(status) {
      if (status) {
        return this.locations.find(el => el.id === this.options.location_checked?.id)?.title.toLowerCase().includes('office')
      }
      return this.locations.find(el => el.id === this.options.prev.location_id)?.title.toLowerCase().includes('office')
    },
    async constructData(status) {
      this.disabledCount = status ? 0 : this.getDisabledCount();
      this.maxLimit = status ? this.options.end_time * 4 : (this.options.end_time * 4) - this.disabledCount
      this.checkInfo.isOfficeEmployee = this.getCurrentLocation(status)
      this.checkInfo.isLunch = status || this.options.is_lunch
          ? this.options.is_lunch
          : this.checkInfo.isOfficeEmployee
              ? this.options.prev.is_lunch
              : false
      this.checkInfo.isLunchButton = this.options.is_lunch
      if (status) {
        this.handlesData = this.options.data.handlesData
        this.connects = this.options.data.connects
      } else {
        if (this.checkInfo.isOfficeEmployee) {
          this.constructHandleDataForOffice()
        } else {
          if (this.options.prev && this.options.prev.prevInterval.length > 0) {
            this.handlesData = []
            this.connects = []
            if (this.getTimePointForTimeline(this.$moment(this.options.prev.prevInterval[0].date_start).format('HH:mm')) < this.disabledCount) {
              let resultDiff = this.getTimePointForTimeline(this.$moment(this.options.prev.prevInterval[0].date_end).format('HH:mm')) - this.getTimePointForTimeline(this.$moment(this.options.prev.prevInterval[0].date_start).format('HH:mm'))
              if (96 - this.disabledCount < 32 || resultDiff > 96 - this.disabledCount) {
                this.handlesData.push(0)
                this.handlesData.push(96 - this.disabledCount)
                this.connects.push(false, true, false)
              }
              if (resultDiff < 96 - this.disabledCount && 96 - this.disabledCount >= 32) {
                this.handlesData.push(0, 16, 20, 32)
                this.connects.push(false, true, false, true, false)
              }
            } else {
              this.options.prev.prevInterval.forEach((item, idx) => {
                this.handlesData.push(this.getTimePointForTimeline(this.$moment(item.date_start).format('HH:mm')) - this.disabledCount)
                this.handlesData.push(this.getTimePointForTimeline(this.$moment(item.date_end).format('HH:mm')) - this.disabledCount)

                if (idx === 0) {
                  this.connects.push(false, true, false)
                } else {
                  this.connects.push(true, false)
                }

              })
            }
          } else {
            this.handlesData = [this.maxLimit - 8, this.maxLimit - 4]
            this.connects = [false, true, false]
          }
        }
      }
      return new Promise((resolve) => {
        resolve(true)
      })
    },
    addHoversEffect() {
      const veeno = this.$refs['veeno'].$el
      const connects = veeno.getElementsByClassName('noUi-connect')
      if (connects.length > 0) {
        for (let i = 0; i < connects.length; i += 1) {
          connects[i].addEventListener('mousemove', () => {
            const el = veeno.getElementsByClassName(`close-action__${i + 1}`)
            if (el.length > 0) {
              el[0].style.transform = 'translateY(10)'
              el[0].style.opacity = 1
            }
          })
          connects[i].addEventListener('mouseleave', () => {
            const el = veeno.getElementsByClassName(`close-action__${i + 1}`)
            if (el.length > 0) {
              el[0].style.opacity = 0
            }
          })
        }
      }
    },
    checkBreakTimes(breaks) {
      let status = false
      for (let i=0; i < breaks.length; i += 1) {
        if ((i + 1) % 4 === 0) {
          let a = this.$moment(this.getFormatToSendCheck(this.handlesData[Math.floor(i - 2)]))
          let b = this.$moment(this.getFormatToSendCheck(this.handlesData[Math.floor(i - 1)]))
          if (b.diff(a, 'minutes') < 59) {
            status = true
            break
          }
        }
        if ((i + 1) % 6 === 0) {
          let a = this.$moment(this.getFormatToSendCheck(this.handlesData[Math.floor(i - 2)]))
          let b = this.$moment(this.getFormatToSendCheck(this.handlesData[Math.floor(i - 1)]))
          if (b.diff(a, 'minutes') < 59) {
            status = true
            break
          }
        }
      }
      return status
    },
    getFormatToSendCheck(time) {
      const res = (parseInt(time) + this.disabledCount) * 15 / 60
      return this.$moment().format('YYYY-MM-DD') + ' ' + this.getTime(res)
    },
    getBreakPoints() {
      const obj = {}
      this.handlesData.forEach((item, idx) => {
        if ((idx + 1) % 4 === 0) {
          obj[`break_start[${Math.floor(idx / 4)}]`] = this.getFormatToSendCheck(this.handlesData[Math.floor(idx - 2)])
          obj[`break_end[${Math.floor(idx / 4)}]`] = this.getFormatToSendCheck(this.handlesData[Math.floor(idx - 1)])
        }
        if ((idx + 1) % 6 === 0) {
          obj[`break_start[${Math.floor(idx / 6) + 1}]`] = this.getFormatToSendCheck(this.handlesData[Math.floor(idx - 2)])
          obj[`break_end[${Math.floor(idx / 6) + 1}]`] = this.getFormatToSendCheck(this.handlesData[Math.floor(idx - 1)])
        }
      })

      return obj
    },
      async acceptCheckIn(){
          if (this.checkBreakTimes(this.handlesData)) {
              this.$bvModal.hide('bv-modal-example')
              return this.$showErrorToast('Break is less than 1 hour')
          } else {

              const data = {
                  date_start: this.getFormatToSendCheck(this.handlesData[0]),
                  date_end: this.getFormatToSendCheck(this.handlesData[this.handlesData.length - 1]),
                  location_id: this.checkInfo.isOfficeEmployee ? this.locations.find(el => el.title.toLowerCase().includes('office'))?.id : REMOTE_LOCATION_ID,
                  is_lunch: this.checkInfo.isLunch,
                  ...this.getBreakPoints(),
              }

              this.$store.dispatch('profile/checkBetaProfile', this.$convertToFormData({...data, _method: 'POST'}))
                  .then(res => {
                      if (res) {
                          this.checkInfo.isCheck = true
                          this.checkInfo.checked_at = res.clicked_at
                          this.$emit('onCheckIn');
                          this.removePlus()
                          this.removeCloseIcons()
                      }
                  })
              await this.$bvModal.hide('bv-modal-example')
          }
      },
      reserveLunch(){
          const data = {
              is_lunch: true,
              ...this.getBreakPoints(),
          }
          this.$store.dispatch('profile/reserveLunch', this.$convertToFormData({...data, _method: 'POST'})).then(res => {
              if (res == true) {
                  this.checkInfo.isLunch = true
                  this.checkInfo.isLunchButton = true
              }
          })
      },
    getArrayFromNumber(number) {
      const result = []
      for (let i = 1; i <= number; i += 1) {
        result.push(i)
      }

      return result
    },
    checkTime (penalty) {
      this.penalty = penalty
      const hours = this.times.split(':');
      if (parseInt(hours[0]) >= 7) {
        this.acceptCheckIn()
      } else {
        this.$bvModal.show('bv-modal-example')
      }
    },
    showSettings () {
        this.$bvModal.show('bv-modal-settings')
    },
    constructHandleDataForOffice() {
      this.isShowTimeLine = false
      const lunchTime = [(this.options.lunchTime[0] * 4) - this.disabledCount, (this.options.lunchTime[1] * 4) - this.disabledCount]
      const limitHourFrom = (this.options.limitOfficeTime.from * 4) - this.disabledCount
      const limitHourTo = (this.options.limitOfficeTime.to * 4) - this.disabledCount
      const result = this.getArrayFromNumber(this.requiredWorkHours).reduce((acc) => {
        for (let j = 1; j <= 4; j += 1) {
          acc.isNotLunch = acc.beforeLunchCount <= lunchTime[0]
          if (acc.isNotLunch) {
            acc.data[0] = limitHourFrom > 0 ? limitHourFrom : 0
            acc.data[1] = acc.beforeLunchCount
            acc.beforeLunchCount += 1
            acc.iterator = 2
          } else {
            acc.data[acc.iterator] = lunchTime[1] > 0 ? lunchTime[1] : 0
            acc.data[acc.iterator + 1] = acc.afterLunchCount
            acc.afterLunchCount += acc.afterLunchCount < limitHourTo ? 1 : 0
          }

          acc.connects = acc.data.length > 2 ? [false, true, false, true, false] : [false, true, false]
        }
        return acc
      }, {
        beforeLunchCount: limitHourFrom > 0 ? limitHourFrom + 1 : 1,
        afterLunchCount: lunchTime[1] > 0 ? lunchTime[1] + 1 : 1,
        data: [],
        connects: [],
        iterator: 0,
        isNotLunch: true
      })
      this.handlesData = result?.data
      this.connects = result?.connects
      this.$nextTick(() => {
        this.isShowTimeLine = true
      })
    },
  },
  beforeDestroy() {
    document.removeEventListener('mousemove', this.addHoversEffect);
    document.removeEventListener('mouseleave', this.addHoversEffect);
    window.removeEventListener('resize', this.handleWindowResize);
  },
  async mounted() {
    this.tooltip = document.getElementsByClassName('time-range__tooltip')[0];
    this.mandatoryTitle = this.getMandatoryTitle()
    this.timeLocaleStart = this.$moment(this.localTime.format('YYYY-MM-DD HH:mm:ss'), 'YYYY-MM-DD HH:mm:ss').diff(this.$moment(this.uzbTime, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'), 'hours')
      this.checkInfo.notificationTime = this.options.notificationTime ?
          this.$moment(this.options.notificationTime, 'HH:mm').add(this.timeLocaleStart, 'hours').format('HH:mm') :
          this.$moment('12:30', 'HH:mm').add(this.timeLocaleStart, 'hours').format('HH:mm')
    this.containerWidth = this.$refs['timeRangeContainer']?.offsetWidth ?? 0
    this.setIntervalForTimeline(60)
    window.addEventListener('resize', this.handleWindowResize);
  }
}
</script>
