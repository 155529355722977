<template>
  <b-card v-if="profile" class="profile-edit card" ref="card">
    <ProfileRequestForm
      :modalId="modalId"
      :email="profile.email"
      :employeeId="profile.uid"
    />
        <b-form @submit="handleFormSubmit">
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <b-form-group
                                label="Имя"
                                label-for="first-name"
                                class="col-lg"
                        >
                            <b-form-input
                                    id="first-name"
                                    v-model="form.firstName"
                                    type="text"
                                    placeholder="Введите имя"
                                    required
                            />
                        </b-form-group>
                        <b-form-group
                                label="Фамилия"
                                label-for="last-name"
                                class="col-lg"
                        >
                            <b-form-input
                                    id="last-name"
                                    v-model="form.lastName"
                                    type="text"
                                    placeholder="Введите фамилию"
                                    required
                            />
                        </b-form-group>
                        <b-form-group
                                label="Отчество"
                                label-for="patronymic"
                                class="col-lg"
                        >
                            <b-form-input
                                    id="patronymic"
                                    v-model="form.patronymic"
                                    type="text"
                                    placeholder="Введите отчество"
                                    required
                            />
                        </b-form-group>
                    </div>
                    <div class="row">
                      <b-form-group
                        v-if="type === 'full'"
                        label="Дата рождения"
                        label-for="dob"
                        class="col-lg-4"
                      >
                        <date-picker
                          v-model="form.dob" type="date"
                          value-type="YYYY-MM-DD"
                          format="DD.MM.YYYY"
                          :input-attr="{ required: 'required', id: 'dob'}"
                        />
                      </b-form-group>
                        <b-form-group
                                label="Country of Birth"
                                label-for="born-place"
                                class="col-lg-4"
                        >
                            <b-form-input
                                    id="born-place"
                                    v-model="form.bornPlace"
                                    type="text"
                                    placeholder="Город, область, республика"
                                    required
                            />
                        </b-form-group>
                        <b-form-group
                          v-if="type === 'short'"
                          label="Country of Residence"
                          label-for="born-place"
                          class="col-lg-4"
                        >
                          <b-form-input
                            id="born-place"
                            v-model="form.countryOfResidence"
                            type="text"
                            placeholder="Город, область, республика"
                            required
                          />
                        </b-form-group>
                        <b-form-group
                            label="Gender"
                            label-for="gender"
                            class="col-lg-4"
                        >
                          <b-form-select
                              id="gender"
                              v-model="form.gender"
                              :options="genders"
                              text-field="title"
                              value-field="value"
                          ></b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <div class="col-lg-4">
                  <AvatarUploader
                    :avatar="profile.photo"
                    @onChange="handleAvatarChange"
                  />
                </div>
            </div>
            <template v-if="profile.type === 'full'">
                <div class="row">
                    <b-form-group
                            label="Адрес фактического места жительства"
                            label-for="address"
                            class="col-lg-8"
                    >
                        <b-form-input
                                id="address"
                                v-model="form.address"
                                type="text"
                                placeholder="Город, улица, дом, квартира"
                                required
                        />
                    </b-form-group>
                    <b-form-group
                            label="Условия проживания"
                            label-for="сonditions"
                            class="col-lg-4"
                    >
                        <b-form-select
                                id="сonditions"
                                v-model="form.accommodationСonditions"
                                :options="conditionOptions"
                                required
                        />
                    </b-form-group>
                </div>
            </template>
            <div class="row">
                <b-form-group
                        label="1. Контактный телефон"
                        label-for="phone1"
                        class="col-lg"
                >
                    <b-form-input
                            id="phone1"
                            v-model="form.phone1"
                            type="text"
                    />
                </b-form-group>
                <b-form-group
                        v-if="profile.type === 'full'"
                        label="2. Контактный телефон"
                        label-for="phone2"
                        class="col-lg"
                >
                    <b-form-input
                            id="phone2"
                            v-model="form.phone2"
                            type="text"
                    />
                </b-form-group>
                <b-form-group
                        label="Личный Email"
                        label-for="email"
                        class="col-lg"
                >
                    <b-form-input
                            id="email"
                            v-model="form.email"
                            type="email"
                    />
                </b-form-group>
            </div>
            <div v-if="profile.type === 'full'" class="mb-3">
                2. Контактный телефон - Укажите один личный контактный телефон близкого человека (супруг, супруга, отец, мать, брат, сестра, сын, дочь, близкий друг), через которого можно с Вами связаться в экстренных случаях
            </div>
            <div v-else-if="profile.type === 'short'" class="row">
                <b-form-group
                        label="CV"
                        label-for="cv"
                        class="col-lg"
                >
                    <b-form-input
                            id="cv"
                            v-model="form.curriculum"
                            type="text"
                            placeholder="Please type here some info about you in general"
                    />
                </b-form-group>
                <b-form-group
                        label="Freelancer's account"
                        label-for="upworkProfileLink"
                        class="col-lg"
                >
                    <b-form-input
                            id="upworkProfileLink"
                            v-model="form.upworkProfileLink"
                            type="text"
                            placeholder=""
                    />
                </b-form-group>
            </div>
            <template v-if="profile.type === 'full'">
                <div class="row">
                    <b-form-group
                            label="Гражданство"
                            label-for="citizenship"
                            class="col-lg-8"
                    >
                        <b-form-input
                                id="citizenship"
                                v-model="form.citizenship"
                                type="text"
                                required
                        />
                    </b-form-group>
                    <b-form-group
                            label="Семейное положение"
                            label-for="maritalStatus"
                            class="col-lg-4"
                    >
                        <b-form-select
                                id="maritalStatus"
                                v-model="form.maritalStatus"
                                :options="maritalStatusOptions"
                                required
                        />
                    </b-form-group>
                </div>
                <Relatives
                        v-if="profileLoaded"
                        :list="form.relatives"
                        @onChange="handleRelativesChange"
                />
                <Educations
                        v-if="profileLoaded"
                        :data="form.educations"
                        @onChange="handleEducationChange"
                />
                <Works
                        v-if="profileLoaded"
                        :list="form.works"
                        @onChange="handleWorksChange"
                />
            </template>
            <SocialLinks
                    v-if="profileLoaded"
                    :list="form.socialLinks"
                    @onChange="handleSocialLinksChange"
            />
            <div class="d-flex justify-content-end">
                <a href="/profile" class="btn btn-danger mr-2">Cancel</a>
                <b-button
                        type="submit"
                        variant="success"
                >
                    Save
                </b-button>
            </div>
        </b-form>
    </b-card>
</template>

<script>
import datePicker from 'vue2-datepicker';
import Works from './works.vue';
import Relatives from './relatives.vue';
import Educations from './educations.vue';
import SocialLinks from './social_links.vue';
import AvatarUploader from '@/components/AvatarUploader';
import ProfileRequestForm from './profile_request_form.vue';
import { convertObjectToFormData } from '@/utils/converters';

export default {
    name: "edit",
    props: ['profile'],
    components: {
        datePicker,
        Works,
        Relatives,
        Educations,
        SocialLinks,
        AvatarUploader,
        ProfileRequestForm
    },
    data() {
        return {
            avatar: null,
            isEditMode: false,
            modalId: 'profile-request-form',
            profileLoaded: false,
            conditionOptions: [
                { value: 'в квартире с родителями', text: 'в квартире с родителями' },
                { value: 'отдельная квартира', text: 'отдельная квартира' },
                { value: 'собственный дом', text: 'собственный дом' },
                { value: 'арендуемая квартира', text: 'арендуемая квартира' },
                { value: 'прочее', text: 'прочее' }
            ],
            maritalStatusOptions: [
                { value: 'не замужем / не женат', text: 'не замужем / не женат' },
                { value: 'разведен / разведена', text: 'разведен / разведена' },
                { value: 'замужем / женат', text: 'замужем / женат' },
                { value: 'состою в гражданском браке', text: 'состою в гражданском браке' },
            ],
            genders: [
              {id: 1, title: 'Male', value: 'male'},
              {id: 2, title: 'Female', value: 'female'},
              {id: 3, title: 'Undefined', value: null},
            ],
            form: {
                firstName: '',
                lastName: '',
                patronymic: '',
                dob: null,
                bornPlace: '',
                address: '',
                accommodationСonditions: null,
                phone1: '',
                phone2: '',
                email: '',
                citizenship: '',
                maritalStatus: null,
                gender: null,
                relatives: [],
                educations: {
                    secondary: [],
                    secondaryProf: [],
                    higher: [],
                    extra: []
                },
                works: [],
                socialLinks: [],
                passport: null,
                inn: null,
                pinfl: null,
                fPassport: null,
                countryOfResidence: null,
                curriculum: null,
                language: null,
                upworkProfileLink: null,
                portfolios: null
            },
            type: this.profile ? this.profile.type : null
        }
    },
    watch: {
        async profile(value) {
            if (value) {
              this.form.firstName = value.first_name;
              this.form.lastName = value.last_name;
              this.form.patronymic = value.middle_name;
              this.form.dob = value.birthday;
              this.form.bornPlace = value.birthplace;
              this.form.countryOfResidence = value.country_of_residence;
              this.form.address = value.address;
              this.form.gender = value.gender ?? null;
              this.form.accommodationСonditions = value.living_condition;
              this.form.phone1 = value.phone;
              this.form.phone2 = value.secondary_contact_phone;
              this.form.pinfl = value.pinfl;
              this.form.email = value.personal_email;
              this.form.citizenship = value.citizenship;
              this.form.maritalStatus = value.marital_status;
              this.form.curriculum = value.curriculum;
              this.form.upworkProfileLink = value.upwork_profile_link;
              this.form.language = value.language;
              this.form.relatives = value.relatives.map((item) => ({
                  type: item.type,
                  name: item.name,
                  birthday: item.birthday,
                  work: item.work,
                  position: item.position,
                  address: item.address
              }));
              this.form.works = value.work_experiences.map((item) => ({
                  dateStart: item.start_date,
                  dateEnd: item.end_date,
                  name: item.title,
                  position: item.position
              }));
              this.form.socialLinks = value.social_networks.map(({ link }) => link);
              this.form.educations = value.educations.reduce((acc, item) => {
                  const defaultFields = {
                      type: item.type,
                      dateStart: item.start_date,
                      dateEnd: item.end_date,
                      title: item.title
                  }
                  if (item.type === 'Среднее') {
                      acc.secondary.push({
                          ...defaultFields
                      });
                  }
                  if (item.type === 'Среднее профессиональное') {
                      acc.secondaryProf.push({
                          ...defaultFields,
                          department: item.department
                      });
                  }
                  if (item.type === 'Высшее') {
                      acc.higher.push({
                          ...defaultFields,
                          department: item.department,
                          speciality: item.speciality
                      });
                  }
                  if (item.type === 'Дополнительное') {
                      acc.extra.push({
                          ...defaultFields
                      });
                  }

                  return acc;
              }, {
                  secondary: [],
                  secondaryProf: [],
                  higher: [],
                  extra: []
              });
              this.type = value ? value.type : null
              this.profileLoaded = true
            }
        }
    },
    methods: {
        handleAvatarChange(image) {
            this.avatar = image;
        },
        handleRelativesChange(relatives) {
            this.form.relatives = [ ...relatives ];
        },
        handleEducationChange(education) {
            this.form.educations = { ...education };
        },
        handleWorksChange(works) {
            this.form.works = [...works];
        },
        handlePortfoliosChange(portfolios) {
            this.form.portfolios = [...portfolios];
        },
        handleSocialLinksChange(socialLinks) {
            this.form.socialLinks = [...socialLinks];
        },
        async handleFormSubmit(event) {
            event.preventDefault();
            let haveErrors = false
            if (this.avatar) {
              await this.$store.dispatch('profile/uploadProfilePhoto', convertObjectToFormData({
                photo: this.avatar
              }));
            }

            const fData = new FormData();
            fData.append('first_name', this.form.firstName);
            fData.append('last_name', this.form.lastName);
            fData.append('middle_name', this.form.patronymic);
            fData.append('gender', this.form.gender);
            if (this?.form?.phone1 && this.form.phone1.length > 0) {
                fData.append('phone', this.form.phone1);
            }
            if (this?.form?.email && this.form.email.length > 0) {
                fData.append('personal_email', this.form.email);
            }
            fData.append('birthplace', this.form.bornPlace);
            if (this.profile.type === 'short') {
                fData.append('type', 'short');
                fData.append('country_of_residence', this.form.countryOfResidence);
                fData.append('curriculum', this.form.curriculum);
                fData.append('upwork_profile_link', this.form.upworkProfileLink);
                fData.append('language', this.form.language);
            } else if (this.profile.type === 'full') {
                fData.append('birthday', this.form.dob);
                fData.append('address', this.form.address);
                fData.append('living_condition', this.form.accommodationСonditions);
                if (this?.form?.phone2 && this.form.phone2.length > 0) {
                    fData.append('secondary_contact_phone', this.form.phone2);
                }
                if (this?.form?.pinfl && this.form.pinfl.length > 0) {
                    fData.append('pinfl', this.form.pinfl);
                }
                fData.append('citizenship', this.form.citizenship);
                fData.append('marital_status', this.form.maritalStatus);
                fData.append('do_not_want_indicate_social_networks', this.form.socialLinks.length ? 1 : 0);
                fData.append('data_responsibility', this.profile.data_responsibility);

                this.form.relatives.forEach((item, index) => {
                    fData.append(`relatives[${index}][type]`, item.type);
                    fData.append(`relatives[${index}][name]`, item.name);
                    fData.append(`relatives[${index}][birthday]`, item.birthday);
                    fData.append(`relatives[${index}][work]`, item.work);
                    fData.append(`relatives[${index}][position]`, item.position);
                    fData.append(`relatives[${index}][address]`, item.address);
                });

                this.form.works.forEach((item, index) => {
                    fData.append(`work_experiences[${index}][title]`, item.name);
                    fData.append(`work_experiences[${index}][end_date]`, item.dateEnd);
                    fData.append(`work_experiences[${index}][position]`, item.position);
                    fData.append(`work_experiences[${index}][start_date]`, item.dateStart);
                });

                let index = 0;
                [...this.form.educations.secondary, ...this.form.educations.extra].forEach((item) => {
                    fData.append(`educations[${index}][end_date]`, item.dateEnd);
                    fData.append(`educations[${index}][start_date]`, item.dateStart);
                    fData.append(`educations[${index}][title]`, item.title);
                    fData.append(`educations[${index}][type]`, item.type);
                    index += 1;
                });

                [...this.form.educations.secondaryProf].forEach((item) => {
                    fData.append(`educations[${index}][end_date]`, item.dateEnd);
                    fData.append(`educations[${index}][start_date]`, item.dateStart);
                    fData.append(`educations[${index}][title]`, item.title);
                    fData.append(`educations[${index}][type]`, item.type);
                    fData.append(`educations[${index}][department]`, item.department);
                    index += 1;
                });

                [...this.form.educations.higher].forEach((item) => {
                    fData.append(`educations[${index}][end_date]`, item.dateEnd);
                    fData.append(`educations[${index}][start_date]`, item.dateStart);
                    fData.append(`educations[${index}][title]`, item.title);
                    fData.append(`educations[${index}][type]`, item.type);
                    fData.append(`educations[${index}][department]`, item.department);
                    fData.append(`educations[${index}][speciality]`, item.speciality);
                    index += 1;
                });
            }
            this.form.socialLinks.forEach((item, index) => {
                fData.append(`social_networks[${index}][link]`, item);
            });
            await this.$store.dispatch('profile/updateProfile', fData)
              .then((response) => {
                if (!response || response.code !== 200) {
                  haveErrors = true;
                }
              })
            if (!haveErrors) {
              setTimeout( () => this.$router.go(), 2000);
            }
        }
    }
}
</script>

<style scoped>

</style>
