<template>
  <b-card ref="profile-panel" class="profile-panel">
    <div class="d-flex align-items-center justify-content-between">
      <div class="d-flex align-items-center">
        <b-avatar
          variant="info"
          size="40px"
          :text="getNameInitials(data?.from?.name)"
          :src="data?.from?.photo"
        />
        <div class="profile-panel__data">
          <div class="d-flex align-items-center">
            <p
              ref="message"
              class="profile-panel__message"
              :class="{ 'profile-panel__message_blured': isTextBlured }"
            >{{ get(data, 'messages[0].message', '') }}</p>
            <i
              class="profile-panel__visibility-button bx"
              :class="isTextBlured ? 'bxs-show' : 'bxs-hide'"
              @click="handleChangeTextBlur"
            />
          </div>
          <p
            class="profile-panel__button text-primary cursor-pointer mb-0"
            @click="isChatModalOpen = true"
          >
            Read more and reply
          </p>
        </div>
      </div>
      <div class="d-flex">
        <i
          class="profile-panel__info bx bx-info-circle text-primary"
          @click="isInfoModalOpen = true"
        />
        <div
          class="profile-panel__progress"
          v-b-tooltip.hover="{ customClass: 'profile-panel__tooltip' }"
          :title="`${daysLeft} days of performance improvement period left`"
          :style="progressStyle"
        >
          <div class="profile-panel__days-spent">{{ daysLeft }}</div>
        </div>
      </div>
    </div>
    <chat-modal
      v-model="isChatModalOpen"
      :warningId="data.id"
    />
    <b-modal
      v-model="isInfoModalOpen"
      size="md"
      centered
      hide-footer
      title="Dismissal Warning Info"
    >
      <h6 class="font-size-16">Предупреждение о возможном увольнении.</h6>
      <p class="m-0 font-size-14 pb-2">
        Предупреждение выносится, если должностные обязанности систематически не выполняются или выполняются некачественно, систематически превышаются сроки выполнения задач, присутствует явный недостаток компетенций для выполнения должностных обязанностей.
        Предупреждение может быть вынесено на срок от двух недель до одного месяца.
        В рамках предупреждения менеджер оговаривает конкретные результаты, которых сотрудник должен достичь за указанный срок.
        При успешном достижении результатов, предупреждение может быть снято.
        Если результаты не достигнуты или достигнуты не в полной мере, то сотруднику грозит увольнение, без выплаты двухнедельной компенсации.
        <br /><br />
        A warning is issued if job duties are not systematically fulfilled or are performed poorly, if deadlines are consistently missed, or if there is a clear lack of competencies to carry out the job duties.
        The warning can be issued for a period ranging from two weeks to one month.
        Within the framework of this warning, the manager will specify the particular results that the employee must achieve within the given time period.
        If the employee successfully achieves the set results, the warning may be lifted.
        However, if the results are not achieved or are only partially achieved, the employee could face dismissal without the payment of a two-week compensation.
      </p>
    </b-modal>
  </b-card>
</template>

<script>
import get from 'lodash/get';
import ChatModal from './chat-modal.vue';
import { getNameInitials } from '@/utils/user';
import { WARNING_PERIOD } from '@/utils/constants';

const STORAGE_KEY = 'dismissal-message-blur';

export default {
  components: {
    ChatModal
  },
  props: {
    data: Object
  },
  data() {
    return {
      isTextBlured: false,
      isInfoModalOpen: false,
      isChatModalOpen: false
    }
  },
  created() {
    this.isTextBlured = localStorage.getItem(STORAGE_KEY) === 'true';
  },
  mounted() {
    this.handleResize();
    if (this.$route.query['dismissal-modal'] === 'true') {
      this.isChatModalOpen = true;
      this.$router.replace({ query: null });
    }
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    get,
    getNameInitials,
    getSpentDaysInPersantages() {
      const totalDays = 30;
      const degresPerOneDay = 360 / totalDays;

      return ((this.data.days_left * degresPerOneDay) * 100) / 360;
    },
    handleChangeTextBlur() {
      this.isTextBlured = !this.isTextBlured;
      localStorage.setItem(STORAGE_KEY, this.isTextBlured);
    },
    handleResize() {
      const message = this.$refs['message'];
      const container = this.$refs['profile-panel'];
      message.style.maxWidth = (container.clientWidth - 160) / 1.2 + 'px';
    }
  },
  computed: {
    daysLeft() {
      const diff = WARNING_PERIOD - this.data.days_left;
      return diff > 0 ? diff : 0;
    },
    progressStyle() {
      return { background: `
        radial-gradient(closest-side, white 18px, transparent 2px),
        conic-gradient(#DDD ${this.getSpentDaysInPersantages()}%, #F75E60 0)`
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-panel {
  border: 1px solid #F75E60;

  .card-body {
    padding: 10px 25px;
  }

  &__button,
  &__message {
    color: #2A3042;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;

    &_blured {
      user-select: none;
      color: transparent;
      pointer-events: none;
      text-shadow: 0 0 10px #000;
    }
  }
  &__message {
    white-space: nowrap;
    overflow: hidden;
    padding: 10px;
    margin: -10px;
    text-overflow: ellipsis;
  }
  &__data {
    margin-left: 10px;
  }
  &__progress {
    width: 40px;
    height: 40px;
    position: relative;
    border-radius: 50%;
  }
  &__days-spent {
    top: 50%;
    left: 50%;
    color: #F75E60;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    position: absolute;
    transform: translate(-50%, -50%);
  }
  &__tooltip {
    width: 128px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__visibility-button {
    font-size: 18px;
    cursor: pointer;
    color: #2A3042;
    margin-left: 10px;
  }
  &__info {
    font-size: 18px;
    cursor: pointer;
    margin-right: 10px;
  }
}
</style>
