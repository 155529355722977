<template>
  <b-modal
    :id="modalId"
    title="Fill profile request"
    centered
    title-class="font-22"
    hide-footer
  >
    <b-form @submit="handleSubmit">
      <b-form-group
        label="Email"
        label-for="email"
      >
        <b-form-input
          id="email"
          v-model="form.email"
          type="email"
          placeholder="Enter email"
          required
        />
      </b-form-group>
      <b-form-group
        label="Profile type"
        label-for="profile-type"
      >
        <b-form-select
          id="profile-type"
          v-model="form.type"
          :options="[
            { value: 'full', text: 'Full'},
            { value: 'short', text: 'Short'},
          ]"
          required
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <b-button
          type="submit"
          variant="primary"
        >
          Send
        </b-button>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { convertObjectToFormData } from '@/utils/converters';

export default {
  name: 'ProfileRequestForm',
  props: ['modalId', 'email', 'employeeId'],
  data() {
    return {
      form: {
        email: this.email,
        type: 'full'
      }
    }
  },
  methods: {
    handleSubmit(event) {
      event.preventDefault();
      this.$store.dispatch('employees/sendFillProfile', {
        id: this.employeeId,
        data: convertObjectToFormData(this.form)
      });
      this.$bvModal.hide(this.modalId);
    }
  }
}
</script>
