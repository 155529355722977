<template>
  <ul class="list-unstyled">
    <li
      v-for="position in employee?.positions"
      :key="position.id"
    >
      {{ employee?.positions.length > 1 ? position.title : '' }}
      <ul
        v-if="position.duties && position.duties.length"
        :class="['list-unstyled duties-list', { 'list-with-padding': employee.positions.length > 1 }]"
      >
        <li
          v-for="duty in position.duties"
          :key="duty.id"
        >
          {{ duty.value }}
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'ResponsibilitiesTab',
  props: ['employee']
}
</script>

<style lang="scss" scoped>
  .duties-list {
    li {
      padding-left: 15px;
      position: relative;

      &::before {
        content: '';
        top: 50%;
        left: 0px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #8336b8;
        position: absolute;
        transform: translateY(calc(-50% + 1px));
      }
    }
  }
  .list-with-padding {
    padding-left: 15px;
  }
</style>
