<template>
  <layout v-load="loading">
    <b-modal
            :id="modalId"
            title="Enter your mobile phone"
            centered
            title-class="font-22"
            hide-footer
    >
      <b-form @submit="handleChangePhone">
        <b-form-group
                label="Phone"
                label-for="phone"
        >
          <b-form-input
                  id="phone"
                  v-model="phone"
                  type="text"
                  maxlength="18"
                  placeholder="Enter your mobile phone"
                  required
          />
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
                  :disabled="disableButton"
                  type="submit"
                  variant="primary"
          >
            Save
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <div class="d-flex align-items-center justify-content-between row-title">
      <h2 class="title-1 p-0">Profile</h2>
      <div class="d-flex align-items-center">
        <div class="d-flex align-items-center mr-4">
          <b-alert class="birthdays-block" align-v="center" fade show variant="primary" v-if="birthdays && birthdays[0]">
            <b-row align-v="center">
              <b-col><i class="fas fa-gift font-size-20"></i>
                Today is the birthday of<span class="birthday-item d-inline-block ml-1"
                                              v-for="(birthday, index) in birthdays"
                                              :key="birthday.uid">
              <strong>{{ birthday.name ? birthday.name : '' }}</strong><span v-if="index !== birthdays.length - 1">, </span></span>
              </b-col>
            </b-row>
          </b-alert>
          <b-alert class="lucky-coin-block ml-2 mb-0" align-v="center" fade show variant="success" v-if="luckyCoins > 0">
            <b-row align-v="center">
              <b-col><i class="fa-regular fa-party-horn"></i>
                Congratulations! Today you have won <strong>{{ luckyCoins }}</strong> BimiCoins
              </b-col>
            </b-row>
          </b-alert>
        </div>
        <user-widget
          :name="profile?.employee?.name"
          :photo="profile?.employee?.photo"
          :position="profile?.employee?.positions?.[0]?.title"
          :department="profile?.employee?.department?.title"
          @on-logout="$keycloak.logout"
        >
          <h5 class="m-0">{{ profile?.employee?.name }}</h5>
        </user-widget>
      </div>
    </div>
    <div v-if="profile && !isEditMode" class="profile">
      <profile-warning-panel
        v-if="dismissalWarning"
        :data="dismissalWarning"
      />
      <TimeRange
        v-if="isShowCheckIn && renderCheckin"
        :options="{
          start_time: 0,
          end_time: 24,
          is_check: timeline.isCheck,
          checked_at: timeline.checked_at,
          location_checked: timeline.location_checked,
          is_lunch: timeline.is_lunch,
          prev: timeline.prevAttendance,
          mandatoryWorkHours: timeline.mandatoryWorkHours,
          data: {handlesData: timeline.handlesData, connects: timeline.connects},
          lunchTime: timeline.lunchTime,
          limitOfficeTime: timeline.limitOfficeTime,
          notificationTime: timeline.notificationTime
        }"
        :location="location"
        :locations="locations"
        @onCheckIn="handleCheckIn"
      />
      <div class="profile-info">
        <div class="row">
          <div class="col-xl-6 col-12">
            <b-card body-class="p-0" class="h-auto">
              <ProfileInfoPanel
                  :user="profile"
                  :balance="balance"
                  @updateProfile="editProfile"
                  @updatePhoto="uploadPhoto"
              />
            </b-card>
          </div>
          <div class="col-xl-6 col-12">
            <b-card body-class="p-0" style="height: calc(100% - 24px)">
              <div class="profile-financial">
                <div class="calendar-block">
                  <div class="d-flex"
                       style="position:relative;"
                       v-load-sm="{
                         load: loadAttendance,
                         style: { top: 36, height: 90 }
                       }"
                  >
                    <Calendar
                        v-if="profile"
                        locale="en-US"
                        :attributes="calendarAttrs"
                        :first-day-of-week="2"
                        @update:to-page="updateCalendar"
                    >
                      <template #day-popover="{ day: { id } }">
                        <div class="profile__calendar-popup" v-html="getCalendarPopoverContent(id)" />
                      </template>
                    </Calendar>
                  </div>
                </div>
                <ProfileFinancialPanel
                    :item="financialInfo"
                    :loading="finInfoLoading"
                    style="flex: 1"
                />
              </div>
            </b-card>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="drop-downs">
            <b-card class="d-block d-md-none">
              <div class="drop-down-block">
                <div
                    :class="['drop-down-block__title', { 'text-primary active': mobileTabIndex === 0 }]"
                    @click="handleMobileTabClick(0)"
                >
                  Responsibilities and Duties
                  <img :src="mobileTabIndex === 0 ? AngleUpBlue : AngleDown">
                </div>
                <ResponsibilitiesTab
                    v-if="mobileTabIndex === 0 && profile"
                    :employee="profile.employee"
                />
              </div>
              <div class="drop-down-block">
                <div
                    :class="['drop-down-block__title', { 'text-primary active': mobileTabIndex === 1 }]"
                    @click="handleMobileTabClick(1)"
                >
                  Equipment
                  <img :src="mobileTabIndex === 1 ? AngleUpBlue : AngleDown" />
                </div>
                <EquipmentTab
                    v-if="mobileTabIndex === 1 && profile"
                    :equipments="profile.equipments"
                />
              </div>
              <div class="drop-down-block">
                <div
                    :class="['drop-down-block__title', { 'text-primary active': mobileTabIndex === 2 }]"
                    @click="handleMobileTabClick(2)"
                >
                  Books
                  <img :src="mobileTabIndex === 2 ? AngleUpBlue : AngleDown" />
                </div>
                <BooksTab
                    v-if="mobileTabIndex === 2 && profile"
                    :books="profile.books"
                />
              </div>
              <div class="drop-down-block">
                <div
                    :class="['drop-down-block__title', { 'text-primary active': mobileTabIndex === 3 }]"
                    @click="handleMobileTabClick(3)"
                >
                  Emails
                  <img :src="mobileTabIndex === 3 ? AngleUpBlue : AngleDown" />
                </div>
                <EmailsTab
                    v-if="mobileTabIndex === 3 && profile"
                    :emails="profile.emails"
                />
              </div>
              <div class="drop-down-block">
                <div
                    :class="['drop-down-block__title', { 'text-primary active': mobileTabIndex === 4 }]"
                    @click="handleMobileTabClick(4)"
                >
                  Services
                  <img :src="mobileTabIndex === 4 ? AngleUpBlue : AngleDown" />
                </div>
                <ServicesTab
                    v-if="mobileTabIndex === 4 && profile"
                    :profile="profile"
                />
              </div>
              <div class="drop-down-block">
                <div
                    :class="['drop-down-block__title', { 'text-primary active': mobileTabIndex === 5 }]"
                    @click="handleMobileTabClick(5)"
                >
                  Softwares
                  <img :src="mobileTabIndex === 5 ? AngleUpBlue : AngleDown" />
                </div>
                <SoftwaresTab
                    v-if="mobileTabIndex === 5 && profile"
                    :softwares="profile.software"
                />
              </div>
            </b-card>
            <b-card class="tabs d-none d-md-block">
              <b-tabs
                  content-class="tab-container p-3 text-muted"
                  nav-class="nav-tabs-custom"
                  v-model="tabIndex"
              >
                <b-tab>
                  <template v-slot:title>
                    <span class="font-size-18 font-weight-bold">Responsibilities and Duties</span>
                  </template>
                  <ResponsibilitiesTab
                      v-if="tabIndex === 0 && profile"
                      :employee="profile.employee"
                  />
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="font-size-18 font-weight-bold">Equipment</span>
                  </template>
                  <EquipmentTab
                      v-if="tabIndex === 1 && profile"
                      :equipments="profile.equipments"
                  />
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="font-size-18 font-weight-bold">Books</span>
                  </template>
                  <BooksTab
                      v-if="tabIndex === 2 && profile"
                      :books="profile.books"
                  />
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="font-size-18 font-weight-bold">Emails</span>
                  </template>
                  <EmailsTab
                      v-if="tabIndex === 3 && profile"
                      :emails="profile.emails"
                  />
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="font-size-18 font-weight-bold">Services <span v-if="countPasswordChangeRequests" class="text-danger">({{countPasswordChangeRequests}})</span></span>
                  </template>
                  <ServicesTab
                      v-if="tabIndex === 4 && profile"
                      :profile="profile"
                  />
                </b-tab>
                <b-tab>
                  <template v-slot:title>
                    <span class="font-size-18 font-weight-bold">Software</span>
                  </template>
                  <SoftwaresTab
                      v-if="tabIndex === 5 && profile"
                      :softwares="profile.software"
                  />
                </b-tab>
              </b-tabs>
            </b-card>
          </div>
        </div>
      </div>
    </div>
    <div class="edit-form" v-load="loadingEdit">
      <EditForm
        v-if="isEditMode"
        :profile="profileEdit"
      />
    </div>
  </layout>
</template>

<script>
import moment from 'moment';
import Calendar from 'v-calendar/lib/components/calendar.umd';
import 'nouislider/distribute/nouislider.min.css';
import TimeRange from "../../../components/profile/TimeRange";
import ProfileWarningPanel from '@/components/dismissal-warning/profile-panel.vue';
import ProfileInfoPanel from "@/components/profile/ProfileInfoPanel.vue";
import ProfileFinancialPanel from "@/components/profile/ProfileFinancialPanel.vue";

import {
  BooksTab,
  EmailsTab,
  ServicesTab,
  SoftwaresTab,
  EquipmentTab,
  ResponsibilitiesTab
} from './tabs';
import EditForm from './edit/index.vue';
import { convertObjectToFormData } from '@/utils/converters';
import AvatarDefault from '@/assets/images/users/avatar-default-rect.png';
import { getPopoverContentByType, getCalendarBetaAttributes } from './utils';
import AngleDown from '@/assets/images/angle-down.png';
import AngleUpBlue from '@/assets/images/angle-up-blue.png';
import UserWidget from '@/components/user-widget.vue';

export default {
  name: 'Profile',
  components: {
    UserWidget,
    Calendar,
    BooksTab,
    EmailsTab,
    ServicesTab,
    EquipmentTab,
    SoftwaresTab,
    ResponsibilitiesTab,
    EditForm,
    TimeRange,
    ProfileWarningPanel,
    ProfileInfoPanel,
    ProfileFinancialPanel
  },
  data() {
    return {
      AngleDown,
      AngleUpBlue,
      moment,
      phone: '',
      tabIndex: 0,
      AvatarDefault,
      localTime: moment().format('YYYY-MM-DD HH:mm:ss'),
      UTCTime: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
      timeDiff: null,
      timeStart: null,
      localTimeStart: null,
      localTimeEnd: null,
      disableRange: false,
      modalId: 'phone-modal',
      isEditMode: false,
      showCalendar: false,
      mobileTabIndex: null,
      calendarAttrs: [{ key: 'today', highlight: true, dates: [new Date()] }],
      showCompensations: false,
      displayCoin: process.env.VUE_APP_DISPLAY_BIMI_COINS,
      isShowCheckIn: false,
      finInfoLoading: false,
      timeline: {
        isCheck: null,
        checked_at: null,
        location_checked: null,
        is_lunch: false,
        notificationTime: null,
        handlesData: [],
        connects: [],
        prevAttendance: {
          prevInterval: [],
          location_id: null,
          is_lunch: false
        },
        mandatoryWorkHours: [14, 15],
        lunchTime: [13, 14],
        limitOfficeTime: {
          from: 8,
          to: 21
        }
      },
      renderCheckin: true
    }
  },
  async created() {
    this.timeDiff = moment.duration(moment(this.localTime).diff(moment(this.UTCTime))).asHours() //Timezone hours diff
    this.timeStart = moment(moment().format('YYYY-MM-DD')+' 04:00:00').add(this.timeDiff, 'hours')
    this.$store.dispatch('balanceDetails/getBalanceRequest', this.user?.user?.uid);
  },
  async mounted() {
    await this.$store.dispatch('attendances/getLocations')
    await this.fetchProfilesData();
    await this.$store.dispatch('profile/getBirthdays');
    await this.fetchFinancialInfo()
    window.addEventListener('focus', this.onWindowFocus);
  },
  beforeDestroy() {
    window.removeEventListener('focus', this.onWindowFocus);
  },
  methods: {
    onWindowFocus() {
      if (!this.timeline.isCheck) {
        this.renderCheckin = false;

        this.$nextTick(() => {
          this.renderCheckin = true;
        });
      }
    },
    async handleCheckIn() {
      await this.fetchProfilesData();
      await this.reloadCalendar();
      await this.fetchFinancialInfo()
    },
    async fetchFinancialInfo() {
      this.finInfoLoading = true
      await this.$store.dispatch('profile/getFinancialInfo').finally(() => {
        this.finInfoLoading = false
      })
    },
    async fetchProfilesData() {
      await this.$store.dispatch('profile/getCheckBetaProfile').then(res => {
        if (res) {
          this.isShowCheckIn = res.is_check_in
          this.timeline.is_lunch = res.is_lunch
          if (res?.prev_attendance) {
            if (res.prev_attendance?.date_interval) {
              this.timeline.prevAttendance = {
                prevInterval: res.prev_attendance?.date_interval ?? [],
                location_id: res.prev_attendance.location_id,
                is_lunch: res.prev_attendance.is_lunch,
              }
            }
          }
          if (res?.attendance) {
            if (res.attendance?.date_interval) {
              res.attendance.date_interval.forEach((item, idx) => {
                this.timeline.handlesData.push(this.getTimePointForTimeline(this.$moment(item.date_start).format('HH:mm')))
                this.timeline.handlesData.push(this.getTimePointForTimeline(this.$moment(item.date_end).format('HH:mm')))

                if (idx === 0) {
                  this.timeline.connects.push(false, true, false)
                } else {
                  this.timeline.connects.push(true, false)
                }
              })
            }
            this.timeline.isCheck = !!res.attendance.checked_at
            this.timeline.checked_at = res.attendance.clicked_at
            this.timeline.location_checked = res.attendance?.location
            this.timeline.is_lunch = res.attendance.is_lunch
          } else {
            this.timeline.isCheck = false;
          }
          if (res?.employeeSettings) {
            this.timeline.notificationTime = res.employeeSettings.notification_time
          }
        }
      })
    },
    async reloadCalendar() {
      this.updateCalendar({ month: this.$moment().format('MM'), year: this.$moment().format('YYYY') });
      this.$store.dispatch('profile/getProfile');
    },
    getTimePointForTimeline(time) {
      const timeSplit = time.split(':')
      const result = (parseInt(timeSplit[0]) * 4) + (parseInt(timeSplit[1]) / 15)
      return result > 0 ? result : 96
    },
    editProfile(){
      this.isEditMode = true
      this.$store.dispatch('profile/getCurrentUserProfile');
    },
    async uploadPhoto(file) {
      await this.$store.dispatch('profile/uploadProfilePhoto', convertObjectToFormData({
        photo: file
      }));
      await this.$store.dispatch('profile/getProfile');
    },
    async updateCalendar({ month, year }) {
      await this.$store.dispatch('attendances/getProfileAttendances', { date: `${this.$moment(`${year}-${month}-01`, 'YYYY-MM-DD').format('YYYY-MM-DD')}`}).then(() => {
        this.calendarAttrs = getCalendarBetaAttributes(month, year, this.attendances)
      })
    },
    getCalendarPopoverContent(date) {
      const attr = this.calendarAttrs.find((item) => item.dates.some((calendarDate) => moment(date).isSame(moment(calendarDate))));
      const item = this.attendances.attendance.find((item) => moment(item.date).isSame(moment(date)));
      return getPopoverContentByType(attr.key, date, item, { currentLocation: this.location, locations: this.locations });
    },
    handleChangePhone(event) {
      event.preventDefault();
      this.$store.dispatch('profile/updateProfilePhone', convertObjectToFormData({ phone: this.phone }));
      this.$bvModal.hide(this.modalId);
    },
    onEditIconClick() {
      this.$bvModal.show(this.modalId);
      this.phone = this.profile ? this.profile.employee.phone : '';
    },
    handleMobileTabClick(index) {
      this.mobileTabIndex = index === this.mobileTabIndex ? null : index;
    },
    setBirthdayAvatarDefault(event) {
      event.target.src = AvatarDefault
    },
    searchItem(password_change_requests) {
      return password_change_requests.some(e => new Date(Date.now()) > new Date(e.due_date) && !e.screenshot)
    },
    findItem(id) {
      return this.profile.employee.services_employees.find(e => e.service_id === id)
    }
  },
  computed: {
    location () {
      if (this.$store.state.profile?.location && this.$store.state.profile.location?.id) {
        return this.$store.state.profile.location
      } else {
        if (this.$store.state.profile?.profile?.employee?.location &&
            this.$store.state.profile.profile?.employee?.location?.id)
        {
          return this.$store.state.profile.profile.employee.location
        }
      }

      return null
    },
    locations () {
      return this.$store.state.attendances.dictionary.locations
    },
    profile() {
      return this.$store.state.profile.profile;
    },
    user() {
      return this.$store.state.user;
    },
    balance() {
      let balance = this.$store.getters['balanceDetails/balance'];
      if (balance <= 0) {
        return 0;
      }
      if (balance >= 1000) {
        balance = parseFloat(balance / 1000).toFixed(1) + 'k';
      }
      return balance;
    },
    profileEdit() {
      return this.$store.getters['profile/profileEdit'];
    },
    birthdays() {
      return this.$store.state.profile.birthdays;
    },
    loading() {
      return this.$store.state.profile.loading;
    },
    loadingEdit() {
      return this.$store.getters['profile/profileEdit']?.loading;
    },
    disableButton() {
      if (!this.phone) {
        return true
      }
      return this.profile ? this.phone === this.profile.employee.phone : true;
    },
    current() {
      return this.$store.state.profile.current;
    },
    luckyCoins() {
      return this.$store.state.profile.luckyCoins;
    },
    timeRangeStart(){ //Start local time hour range
      return parseInt(this.timeStart.format('H'))
    },
    passwordChangeRequests() {
      if (this.profile?.services && this.profile?.services.length > 0) {
        return this.profile?.services.map(service => {
          return {
            ...service,
            password_change_requests: this.findItem(service.id)?.password_change_requests
          }
        })
      }

      return []
    },
    countPasswordChangeRequests() {
      const list = this.passwordChangeRequests
      return list?.sort((a) => a.password_change_requests.find(e => !e.screenshot) ? -1 : 1)
        .filter(item => item.password_change_requests && this.searchItem(item.password_change_requests))
        ?.length
    },
    loadAttendance() {
      return this.$store.state.attendances.loading
    },
    attendances () {
      return this.$store.state.attendances.profileAttendances
    },
    dismissalWarning() {
      return (this.profile?.dismissal_warning ?? []).find(({ status }) => status === 'pending');
    },
    financialInfo() {
      return this.$store.state.profile?.financialInfo
    }
  }
}
</script>
<style lang="scss" scoped>
  .row-title {
    padding-right: 1.25rem;
    padding-bottom: 1.5rem;
  }
  .profile {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    &-info {
      height: 100%;
      .profile-financial {
        display: flex;
        padding: 20px 20px 0 0;
        @media screen and (max-width: 575px) {
          flex-direction: column;
          padding: 20px 10px;
          height: auto;
        }
      }
    }
    .drop-downs{
      flex: 1;
      overflow: hidden;
      .card-body{
        display: flex;
        flex: 1;
        height: 100%;
      }
    }
  }
  .tabs {
    flex-grow: 1;
    margin: 0;
  }
  .events {
    width: 300px;
    margin-left: 30px;
    overflow: auto;

    @media(max-width: 768px) {
      width: 100%;
      margin-left: 0px;
    }
  }
  .profile-block {
    width: calc(50% - 180px);

    span, a, p {
      font-size: 16px;
    }

    &__container {
      display: grid;
      align-items: stretch;
      grid-column-gap: 20px;
      grid-template-columns: 235px 1fr;
    }

    &__photo {
      width: 100%;
      height: 235px;
      position: relative;
      overflow: hidden;
      border-radius: 10px;

      img {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
      }

      .photo-uploader {
        z-index: 2;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 46px;
        position: absolute;
        background: rgba(#000, 0.3);

        label {
          width: 100%;
          line-height: 46px;
          font-size: 16px;
          cursor: pointer;
          text-align: center;
        }
        &__btns {
          height: 100%;
          div {
            width: 50%;
            cursor: pointer;
            text-align: center;
          }
        }
      }
    }

    &__info {
      align-items: start;
      justify-content: space-between;
    }

    .profile-block__department {
      font-size: 12px;
      font-weight: bold;
      padding: 4px 10px;
    }
    .profile-block__profile-link {
      font-size: 12px;
      cursor: pointer;

      i {
        margin-top: 1px;
      }
    }
    .profile-block__name {
      font-size: 18px;
    }
    &__row-actions {
      display: none;
    }
  }
  .calendar-block {
    width: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    .vc-container {
      border: none;
    }
  }
  .time-block {
    width: calc(50% - 180px);
    .card-body {
      padding: 0;
      .worktime-block{
        padding: 30px 35px;
      }
    }
  }
  .work-range{
    .work-hours{
      display:flex;
      span{
        width: 10%;
        text-align: center;
      }
    }
  }
  i {
    cursor: pointer;
  }
  .bimi-coin {
    padding-left: 20px;
    position: relative;
    cursor: pointer;
  }
  .bimi-coin__logo {
    font-size: 12px !important;
    text-align: center;
    font-weight: 900;
    width: 16px;
    height: 16px;
    background: #e2b504;
    color: #FFFFFF;
    position: absolute;
    border-radius: 50% 50%;
    left: 0;
    top: 3px;
  }
  .bimi-coin__total {
    text-decoration: underline !important;
  }
  @media(max-width: 1700px) {
    .profile-block {
      &__container {
        grid-template-columns: 220px 1fr;
      }
      &__photo {
        height: 220px;
      }
    }
  }
  @media (max-width: 1570px) {
    .profile-block {
      &__container {
        grid-template-columns: 150px 1fr;
      }

      &__photo {
        height: 150px;
      }
    }
  }

  @media (max-width: 1450px) {
    .profile{
      height: auto;
      .drop-downs{
        max-height: 400px;
      }
    }
    .profile-block {
      line-break: anywhere;
      padding: 0 !important;
      width: calc(50% - 70px);

      &__container {
        grid-template-columns: 170px 1fr;
      }

      &__photo {
        height: 170px;
      }
      &__row-actions {
        display: block;
      }
      &__column-actions {
        display: none;
      }
    }
    :deep(.vc-header),
    :deep(.vc-arrows-container) {
      padding-top: 0px;
    }
    .vc-container {
      margin-top: -6px;
    }
    .time-block {
      width: calc(50% - 275px);
      .card-body .worktime-block {
        padding: 20px;
      }
    }
  }

  @media(max-width: 1200px) {
    .profile-block {
      width: calc(100% - 330px);
      &__container {
        grid-template-columns: 250px 1fr;
      }
      &__row-actions {
        display: none;
      }
      &__column-actions {
        display: block;
      }
      &__info {
        text-align: left;
        align-items: flex-start;
      }
      &__photo {
        height: 250px;
      }
      .profile-block__number {
        justify-content: flex-start;
        word-break: break-all;
      }
    }
    .time-block {
      width: 100%;
    }
  }

  @media (max-width: 1150px) {
    .profile-block {
      &__container {
        grid-template-columns: 170px 1fr;
      }
      &__photo {
        height: 170px;
      }
      .profile-block__department {
        font-size: 10px;
      }
      .profile-block__profile-link {
        font-size: 11px;

        i {
          margin-top: 0.5px;
        }
      }
      span, a, p {
        font-size: 14px;
      }
      .profile-block__name {
        font-size: 16px;
      }
    }
  }
  @media (max-width: 992px) {
    .profile-block {
      &__container {
        grid-template-columns: 220px 1fr;
      }
      &__photo {
        height: 220px;
      }
      .profile-block__department {
        font-size: 12px;
      }
      .profile-block__profile-link {
        font-size: 12px;

        i {
          margin-top: 1px;
        }
      }
      span, a, p {
        font-size: 16px;
      }
      .profile-block__name {
        font-size: 18px;
      }
    }
  }
  @media (max-width: 860px) {
    .profile-block {
      &__container {
        grid-template-columns: 200px 1fr;
      }
      &__photo {
        height: 200px;
      }
      .profile-block__department {
        font-size: 10px;
      }
      .profile-block__profile-link {
        font-size: 12px;
        padding-top: 3px;
        padding-bottom: 2px;

        i {
          margin-top: 0px;
        }
      }
      span, a, p {
        font-size: 14px;
      }
      .profile-block__name {
        font-size: 16px;
      }
    }
    @media (max-width: 768px) {
      .profile {
        height: auto;
        .drop-downs {
          max-height: unset;
          .card-body {
            display: block;
          }
        }
      }
      .worktime-block {
        padding: 30px;
      }
      .profile-block {
        &__container {
          grid-template-columns: unset;
        }
        width: 100%;
        &__info {
          width: 100%;
          text-align: center;
          align-items: center;
        }
        &__photo {
          width: auto;
          height: auto;
          padding: 0px 10px;
          margin-top: 5px;
          margin-right: 0px;
          margin-bottom: 32px;

          img {
            width: 100%;
            position: static;
          }
        }
        .profile-block__container {
          flex-direction: column;
        }
        .profile-block__number {
          justify-content: center;
        }
        .photo-uploader {
          label, &__btns {
            font-size: 14px;
          }
        }
        .profile-block__department {
          font-size: 12px;
        }
        .profile-block__profile-link {
          font-size: 12px;
          padding-top: 4px;
          padding-bottom: 3px;
        }
        span, a, p {
          font-size: 16px;
        }
        .profile-block__name {
          font-size: 18px;
        }
        .bimi-coin {
          display: flex;
          padding-left: 0px;
          align-items: center;
          justify-content: center;

          &__logo {
            display: block;
            position: static;
            margin-right: 10px;
          }
          &__total {
            margin-left: 5px;
          }
        }
      }
      .time-block {
        h4 {
          font-size: 18px;
        }
      }
      .calendar-block {
        width: 100%;
        margin-left: 0px;
      }
    }
    @media(max-width: 450px) {
      .profile-block {
        .photo-uploader {
          height: 26px;
          label, &__btns {
            font-size: 10px;
          }
          label {
            line-height: 26px;
          }
        }
      }
    }
  }
  .drop-down-block {
    &__title {
      display: flex;
      align-items: center;
      height: 40px;
      color: #2a3042;
      font-size: 18px;
      font-weight: bold;

      img {
        margin-top: 3px;
        margin-left: 12px;
      }
    }
  }
</style>

<style lang="scss">
  .profile {
    font-family: 'Inter', sans-serif;
    .drop-downs {
      display: flex;
      .card-body {
        padding: 15px 29px;
        .tabs{
          display: flex;
          flex-direction: column;
        }
        .tab-container{
          overflow: auto;
          height: 100%;
        }
      }

      @media(max-width: 768px) {
        display: block;
      }
    }
    .calendar-block {
      * {
        font-family: 'Inter', sans-serif !important;
      }
      .vc-container {
        border: none;
      }
      .vc-day-layer .vc-highlight {
        border-radius: 6px !important;
      }
      .vc-popover-content-wrapper {
        // pointer-events: none;
        background: #fff !important;
      }
      .vc-popover-content {
        border: none;
        padding: 15px;
        color: #666666;
        border-radius: 10px;
        background: #eeeef9;
        box-shadow: 0px 0px 10px 5px rgb(0 0 0 / 10%);
      }
      .vc-popover-caret {
        display: none;
      }
      .custom-control-label {
        padding-top: 1px;
        vertical-align: middle;
      }
      .vc-day.is-not-in-month * {
        opacity: 1;
        pointer-events: auto;
        color: rgb(116, 120, 141);
      }
    }
    .custom-checkbox-success {
      .custom-control-input {
        & ~ label {
          span {
            font-weight: bold;
            color: #34c38f !important;
          }
          &::before {
            background-color: #34c38f !important;
            border-color: #34c38f !important;
          }
        }
      }
    }
    .custom-checkbox-danger {
      .custom-control-input {
        & ~ label {
          span {
            font-weight: bold;
            color: #f46a6a !important;
          }
          &::before {
            background-color: #f46a6a !important;
            border-color: #f46a6a !important;
          }
        }
      }
    }
  }

  .calendar-block .vc-day-content{
    font-weight: 400 !important;
  }
  .calendar-block .vc-day-content.font-weight-bold {
    font-weight: 600 !important;
  }

  .calendar-block .vc-day-content:hover,
  .calendar-block .vc-day-content.vc-focusable{
    border-radius: var(--rounded);
  }

  .time-block .custom-checkbox.b-custom-control-lg label.custom-control-label::before{
    border-radius: .25rem !important;
  }

  .calendar-block .is-today .vc-day-content{
    font-weight: bold !important;
    background: none;
  }
  .vc-grid-focus, .vc-nav-item.is-current {
    color: var(--gray-900) !important;
  }
  .vc-nav-item.is-current {
    border-color: var(--gray-900) !important;
  }
  .vc-grid-focus:hover, .vc-nav-item.is-current:hover {
    color: var(--accent-100) !important;
  }

  .birthdays-block{
    display: inline-block;
    margin: 0;
    padding: 8px 10px;
    color: #536adb;
    font-size: 14px;
    .row{
      i {
        font-size: 1.5rem;
      }
    }
    @media (max-width: 992px) {
      display: none;
    }
  }

  .lucky-coin-block{
    display: inline-block;
    padding: 8px 10px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .veeno{
    background: unset;
    height: auto;
    border: unset;
    box-shadow: unset;
    .noUi-base{
      height: 18px;
      border: 1px solid #CCCCCC;
      border-radius: 5px;
      pointer-events: none;
    }
    .noUi-pips{
      position: relative;
      height: 50px;
    }
  }
  .veeno[disabled='disabled']{
    .noUi-base{
      background-color: #CCCCCC;
    }
    .noUi-connect.noUi-draggable{
      background-color: #34c38f;
      cursor: unset;
    }
    .noUi-connect.noUi-draggable::before, .noUi-connect.noUi-draggable::after{
      content: unset;
    }
  }
  .noUi-handle{
    display: none;
  }
  .noUi-connects{
    overflow: unset;
    >div:first-child{
      background-color: #CCCCCC;
    }
  }
  .noUi-connect.noUi-draggable{
    background-color: #34c38f;
    transition: all 0.2s ease-out;
    pointer-events: all;
  }
  .noUi-connect.noUi-draggable::before, .noUi-connect.noUi-draggable::after{
    content: "";
    background-color: #34c38f;
    display: block;
    width: 30px;
    height: 30px;
    margin: -7px 0 0 0;
    border-radius: 5px;
  }
  .noUi-connect.noUi-draggable::after{
    position: absolute;
    top: 0;
    right: 0;
  }
  .noUi-value{
    color: #333333;
    font-size: 1rem;
  }

  .worktime-block{
    .local-hours{
      color: #999999;
    }
    .work-time{
      padding: 10px 0;
      border-top: 2px solid #dddddd;
      border-bottom: 2px solid #dddddd;
    }
    .uzt-time{
      .noUi-pips{
        position: relative;
        height: 50px;
        padding-top: 0;
      }
      .noUi-value{
        top: 0;
        color: #DDDDDD;
        transform: translate(-50%,0);
      }
      .noUi-marker{
        top: 25px;
        background: #DDDDDD;
      }
    }
  }

</style>
