<template>
  <div v-if="profile">
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in sortPasswordChangeRequests"
        :key="item.title + index"
        class="d-flex justify-content-between"
      >
        <div>
          <a :href="item.url">{{ item.title }}</a>
          <p class="mt-1">{{ item.description }}</p>
          <p class="mb-1">Status: <span class="text-dark">{{ item.role }}</span></p>
          <p class="mb-0">Registered email: <span class="text-dark">{{ item.email }}</span></p>
        </div>
        <div v-if="item.password_change_requests && searchItem(item.password_change_requests)" class="col-5 text-right">
          <p class="mb font-size-14 text-danger">
            Please change password and confirm that you’ve changed the password
          </p>
          <button @click="approve(item)" class="btn btn-primary">
<!--            <input @change="fileChangeHandler($event, item)" type="file" hidden>-->
            Confirm
          </button>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import { convertObjectToFormData } from '@/utils/converters'
export default {
  name: 'ServicesTab',
  props: ['profile'],
  computed: {
    passwordChangeRequests() {
      return this.profile.services.map(service => {
        return {
          ...service,
          password_change_requests: this.findItem(service.id)?.password_change_requests
        }
      })
    },
    sortPasswordChangeRequests() {
      const list = this.passwordChangeRequests
      return list.sort((a) => a.password_change_requests.find(e => !e.screenshot) ? -1 : 1)
    }
  },
  methods: {
    findItem(id) {
      return this.profile.employee.services_employees.find(e => e.service_id === id)
    },
    searchItem(password_change_requests) {
      return password_change_requests.some(e => new Date(Date.now()) > new Date(e.due_date) && !e.screenshot)
    },
    async fileChangeHandler(e, item) {
      const passwordChangeRequest = item.password_change_requests.find(e => !e.screenshot)
      if (e && e.target.files.length && passwordChangeRequest) {
        const file = e.target.files[0]
        this.$store.dispatch('profile/passwordChange', convertObjectToFormData({
          password_change_request_id: passwordChangeRequest.id,
          screenshot: file
        }))
          .then(() => {
            this.$store.dispatch('profile/getProfile');
          })
      }
    },
    approve(item) {
      const passwordChangeRequest = item.password_change_requests.find(e => !e.screenshot)
      if (passwordChangeRequest) {
        this.$swal.fire({
          title: 'Have you successfully reset the password?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes',
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch('profile/passwordChange', convertObjectToFormData({
              password_change_request_id: passwordChangeRequest.id
            }))
              .then(() => {
                this.$store.dispatch('profile/getProfile');
              })
          }
        })
      }
    }
  },
}
</script>
