<template>
  <div
    v-if="Object.keys(apps).length"
    class="apps-widget"
  >
    <input
      v-model="search"
      type="text"
      class="apps-widget__search"
      placeholder="Type to search..."
    />
    <div class="apps-widget__list">
      <div
        v-for="[title, apps] in Object.entries(filteredApps)"
        :key="title"
        class="apps-item"
      >
        <p class="apps-item__title">{{ title }}</p>
        <div class="apps-item__list">
          <a
            v-for="app in apps"
            :key="app.id"
            :href="app.url"
            target="_blank"
            class="apps-widget__app app-item"
          >
            <img :src="app.photoUrl" alt="" class="app-item__icon" />
            <p class="app-item__name">{{ app.title }}</p>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const sortByTitle = (a, b) => a.title.localeCompare(b.title);

const sortAppsGroups = (apps) => {
  return Object.keys(apps)
    .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    .reduce((acc, key) => {
      acc[key] = apps[key];
      return acc;
    }, {});
}

export default {
  props: {
    token: String,
    baseUrl: String
  },
  data() {
    return {
      apps: {},
      search: '',
    }
  },
  methods: {
    async fetchApps() {
      try {
        const result = await fetch(`${this.baseUrl}/employee-widget-services`, {
          headers: {
            ["Authorization"]: `Bearer ${this.token}`
          }
        });
        const { code, data } = await result.json();

        if (code === 200 && data.length) {
          this.apps = data.reduce((acc, item) => {
            if (acc[item.groupTitle]) {
              acc[item.groupTitle].push(item);
            } else {
              acc[item.groupTitle] = [item];
            }
            return acc;
          }, {});
        }
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    filteredApps() {
      if (!this.search) {
        Object.values(this.apps).forEach(list => list.sort(sortByTitle));
        return sortAppsGroups(this.apps);
      }

      const filtered = {};
      const searchTerm = this.search.toLowerCase();

      Object.entries(this.apps).forEach(([title, apps]) => {
        const list = apps
          .filter(({ title }) => title.toLowerCase().includes(searchTerm))
          .sort(sortByTitle);
        if (list.length) {
          filtered[title] = list;
        }
      });

      return sortAppsGroups(filtered);
    }
  },
  mounted() {
    this.fetchApps();
  },
};
</script>

<style lang="scss" scoped>
.apps-widget {
  &__list {
    max-height: 235px;
    overflow-y: auto;
    margin-right: -20px;
    padding-right: 20px;
  }

  &__search {
    width: 100%;
    height: 32px;
    padding: 0px 11px;
    border-radius: 4px;
    margin-bottom: 20px;
    border: 1px solid #dcdfe6;

    &:focus-visible {
      outline: none;
      border: 1px solid #409eff;
    }
  }
}
.apps-item {
  &__title {
    font-size: 9px;
    font-weight: 800;
    color: #919191;
    margin-bottom: 0px;
    padding-bottom: 5px;
    text-transform: uppercase;
    border-bottom: 1px solid #DCDCDC;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
.app-item {
  display: flex;
  height: 98px;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  &:hover {
    background-color: #f9f9f9;
  }

  &__icon {
    width: 36px;
    height: 36px;
    object-fit: cover;
    border-radius: 4px;
  }

  &__name {
    font-size: 12px;
    font-weight: 500;
    margin-top: 7px;
    margin-bottom: 0px;
    color: #000;
    text-align: center;
  }
}
</style>