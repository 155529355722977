<template>
  <div class="avatar-uploader">
    <b-avatar
      size="12rem"
      :src="image || AvatarDefault"
    />
    <label
      v-if="!showMode"
      class="mt-2"
      for="avatar-input"
    >
      <i class="bx bxs-cog mr-1" /> Change picture
    </label>
    <input
      id="avatar-input"
      hidden
      type="file"
      @change="onFileChange"
      accept="image/png, image/gif, image/jpeg"
    />
  </div>
</template>

<script>
import AvatarDefault from '@/assets/images/users/avatar-default.svg';

export default {
  name: 'AvatarUploader',
  props: {
    avatar: {
      default: null
    },
    showMode: {
      default: false
    }
  },
  data() {
    return {
      AvatarDefault,
      preview: '',
      fReader: new FileReader()
    }
  },
  created() {
    this.fReader.onloadend = () => {
      this.preview = this.fReader.result;
    }
  },
  methods: {
    onFileChange(event) {
      if (event.target.files.length) {
        const [file] = event.target.files;
        this.fReader.readAsDataURL(file);
        this.$emit('onChange', file);
      }
    },
  },
  computed: {
    image() {
      return this.preview || this.avatar;
    }
  }
}
</script>

<style lang="scss" scoped>
  .avatar-uploader {
    display: flex;
    align-items: center;
    flex-direction: column;
    
    label {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
</style>