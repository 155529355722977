<template>
  <div>
    <div
        class="time-range__current-arrow"
        ref="current-arrow"
        :style="config.style ? {...config.style} : {}"
    >
      <i>
        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.6 0.533334C5.8 0.266667 6.2 0.266667 6.4 0.533333L11.4 7.2C11.6472 7.52962 11.412 8 11 8H1C0.587977 8 0.352786 7.52962 0.6 7.2L5.6 0.533334Z" fill="#F75E60"/>
        </svg>
      </i>
    </div>
  </div>
</template>

<script>
export default {
  name: "RedTimeArrow",
  props: {
    containerWidth: {
      type: Number,
      default: 0
    },
    time: {
      type: String,
      default: null
    },
    config: {
      type: Object,
      default: () => {
        return {
          style: null,
          excludePaddingPx: 0
        }
      }
    }
  },
  methods: {
    moveCurrentArrow() {
      if (this.containerWidth > 0) {
        const paddingInPixels = this.config.excludePaddingPx > 0
            ? (this.containerWidth * this.config.excludePaddingPx) / 100
            : 0
        const pixelsInOneMeasurment = (this.containerWidth - paddingInPixels) / (24 * 60);

        const [hours, minutes] = this.$moment(this.time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm').split(':').map((item) => +item);
        const minutestm = (hours * 60) + minutes;

        if (this.$refs['current-arrow']) {
          this.$refs['current-arrow'].style.left =
              (minutestm * pixelsInOneMeasurment) + paddingInPixels - this.$refs['current-arrow'].offsetWidth / 2 + 'px'
        }
      }
    },
  },
  watch: {
    containerWidth: {
      handler() {
        this.moveCurrentArrow()
      }
    },
    time: {
      async handler() {
        this.moveCurrentArrow()
      }
    }
  },
  mounted() {
    this.moveCurrentArrow()
  }
}
</script>

<style scoped lang="scss">

.time-range {
  &__current-arrow {
    position: absolute;
    bottom: -15px;
    left: 0;
  }
}

</style>
