<template>
  <b-list-group>
    <b-list-group-item
      v-for="book in books"
      :key="book.id"
      class="d-md-flex justify-content-between align-items-center"
    >
      <div class="d-sm-flex">
        <div class="book-img mr-sm-3 mb-2 mb-sm-0">
          <img :src="book.photo" alt="" />
        </div>
        <div>
          <div class="mb-1"><span class="mr-1 text-dark">Title:</span>{{ book.title }}</div>
          <div class="mb-1"><span class="mr-1 text-dark">Author:</span>{{ book.author }}</div>
          <div class="mb-1"><span class="mr-1 text-dark">Category:</span>{{ book.category }}</div>
          <div><span class="mr-1 text-dark">Taken:</span>{{ convertDateToFormat(book.taken, 'DD.MM.YYYY') }}</div>
        </div>
      </div>
      <div class="mt-3 mt-md-0">
        <b-button
          variant="warning"
          class="mr-2"
          @click="handleReturnBook(book.id)"
        >
          Return back
        </b-button>
        <b-button
          variant="primary"
          @click="hadleShowBook(book.id)"
        >
          Show
        </b-button>
      </div>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { convertDateToFormat } from '@/utils/converters';

export default {
  name: 'LibraryTab',
  props: ['books'],
  data() {
    return {
      convertDateToFormat
    }
  },
  methods: {
    hadleShowBook(id) {
      this.$router.push(`/library/${id}`);
    },
    async handleReturnBook(id) {
      await this.$store.dispatch('library/returnBook', id);
      await this.$store.dispatch('profile/getProfile');
    }
  }
}
</script>

<style lang="scss" scoped>
  .book-img {
    width: 90px;
    height: 120px;
    overflow: hidden;
    border-radius: 4px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @media(max-width: 575px) {
      width: 120px;
      height: auto;

      img {
        height: auto;
      }
    }
  }
</style>
