import moment from 'moment';

export const getPopoverContentByType = (type, date, info, objLocation) => {
  let mock = '';
  const location = (info ? objLocation.locations.find(item => item.id === info.location_id)?.title : objLocation.currentLocation?.title) ?? '';
  if (info && info.date_interval.length > 0) {
    info.date_interval.forEach((item) => {
      if (info.date_interval.length === 1) {
        mock += `${moment(item.date_start).format('HH:mm')}-${moment(item.date_end).format('HH:mm')}`
      } else {
        mock += `<br />${moment(item.date_start).format('HH:mm')}-${moment(item.date_end).format('HH:mm')}`
      }
    })
  }
  if (type === 'holidays') {
    return `
      <div>
        <p><strong>Day type:</strong> holiday</p>
        <p><strong>Location:</strong> ${location.toLowerCase()}</p>
      </div>
    `;
  }
  if (type === 'default') {
    return `
      <div>
        <p><strong>Day type:</strong> workday</p>
        <p><strong>Location:</strong> ${location.toLowerCase()}</p>
      </div>
    `;
  }
  if (type === 'successDay') {
    return `
      <div>
        <p><strong>Day type:</strong> workday</p>
        <p><strong>Location:</strong> ${location.toLowerCase()}</p>
        <p><strong>Working time:</strong> ${mock}</p>
        ${ (info && (info.overtime_hours > 0 || info.overtime_money > 0)) ? `<p><strong>Overtime:</strong> ${info.overtime_hours ? info.overtime_hours + ' hours' : info.overtime_money + ' $'}<p>` : '' }
      </div>
    `;
  }
  if (type === 'warningDay' || type === 'dangerDay') {
    return `
      <div>
        <p><strong>Day type:</strong> workday</p>
        <p><strong>Location:</strong> ${location.toLowerCase()}</p>
        ${mock.length > 0 ? `<p><strong>Working time: </strong>${mock}</p>` : ''}
        ${info && (info.penalty + info.vacation_hour_spent > 0) ? `<p><strong>Penalty:</strong> ${info.penalty + info.vacation_hour_spent} hours</p>`: ''}
        ${ (info && (info.overtime_hours > 0 || info.overtime_money > 0)) ? `<p><strong>Overtime: </strong> ${info.overtime_hours ? info.overtime_hours + ' hours' : info.overtime_money + ' $'}<p>` : '' }
      </div>
    `;
  }
  if (type === 'dayOffFuture' || type === 'dayOff') {
    return `
      <div>
        <p><strong>Day type:</strong> day-off</p>
        ${location ? `<p><strong>Location:</strong> ${location.toLowerCase()}</p>` : ''}
        ${info && info.penalty > 0 ? `<p><strong>Penalty:</strong> ${info.penalty} hours</p>`: ''}
        ${info && info.vacation_hour_spent > 0 ? `<p><strong>Vacation Hours Spent:</strong> ${info.vacation_hour_spent} hours</p>`: ''}
        ${ (info && (info.overtime_hours > 0 || info.overtime_money > 0)) ? `<p><strong>Overtime:</strong> ${info.overtime_hours ? info.overtime_hours + ' hours' : info.overtime_money + ' $'}<p>` : '' }
      </div>
    `;
  }
  if (type === 'nonWorking') {
    return `
      <div>
        <p><strong>Day type:</strong> Non-working day</p>
        <p><strong>Location:</strong> ${location.toLowerCase()}</p>
      </div>
    `;
  }
  if (type === 'defaultFuture') {
    return `
      <div>
        <p><strong>Day type:</strong> workday</p>
        <p><strong>Location:</strong> ${location.toLowerCase()}</p>
      </div>
    `;
  }
};


export const getCalendarBetaAttributes = (month, year, obj) => {
  const options = {
    default: {
      highlight: {
        class: 'bg-workday',
        contentStyle: {
          color: '#333'
        }
      },
      dates: [],
      popover: {
        visibility: 'hover'
      }
    },
    nonWorking: {
      highlight: {
        class: 'bg-weekend',
        contentStyle: {
          color: '#747373'
        }
      },
      dates: [],
      popover: {
        visibility: 'hover'
      }
    },
    successDay: {
      highlight: {
        class: 'bg-success-day',
        contentStyle: {
          color: '#333'
        }
      },
      dates: [],
      popover: {
        visibility: 'hover'
      }
    },
    warningDay: {
      highlight: {
        class: 'bg-warning-day',
        contentStyle: {
          color: '#333'
        }
      },
      dates: [],
      popover: {
        visibility: 'hover'
      }
    },
    dangerDay: {
      highlight: {
        class: 'bg-danger-day',
        contentStyle: {
          color: '#333'
        }
      },
      dates: [],
      popover: {
        visibility: 'hover'
      }
    },
    dayOffFuture: {
      highlight: {
        class: 'bg-future-dayoff',
        contentStyle: {
          color: '#333'
        }
      },
      dates: [],
      popover: {
        visibility: 'hover'
      }
    },
    defaultFuture: {
      highlight: {
        class: 'bg-default-future',
        contentStyle: {
          color: '#333'
        }
      },
      dates: [],
      popover: {
        visibility: 'hover'
      }
    },
    dayOff: {
      highlight: {
        class: 'bg-dayoff',
        contentStyle: {
          color: '#333'
        }
      },
      dates: [],
      popover: {
        visibility: 'hover'
      }
    },
    holidays: {
      highlight: {
        class: 'bg-holidays',
        contentStyle: {
          color: '#333'
        }
      },
      dates: [],
      popover: {
        visibility: 'hover'
      }
    }
  }
  const maxDay = moment(`${month}-${year}`, 'MM-YYYY').daysInMonth();
  const getStatusAttendance = (attendance, {date}) => {
    if (attendance.checked_at) {
      if (attendance.vacation_hour_spent + attendance.penalty === 0) {
        options.successDay.dates.push(date)
        return;
      }
      if (attendance.vacation_hour_spent + attendance.penalty > 0 && attendance.vacation_hour_spent + attendance.penalty < 8) {
        options.warningDay.dates.push(date)
        return;
      }
      if (attendance.vacation_hour_spent + attendance.penalty === 8) {
        options.dangerDay.dates.push(date)
        return;
      }
    }
    if (!attendance.checked_at && moment(attendance.date, 'YYYY-MM-DD').isAfter(moment()) && attendance.attendance_type.id === 3) {
      options.dayOffFuture.dates.push(date)
      return;
    }
    if (!attendance.checked_at && moment(attendance.date, 'YYYY-MM-DD').isAfter(moment()) && attendance.attendance_type.id === 1) {
      options.defaultFuture.dates.push(date)
      return;
    }
    if (!attendance.checked_at && attendance?.attendance_type?.id === 3) {
      options.dayOff.dates.push(date)
      return;
    }
    if (!attendance.checked_at && attendance?.attendance_type?.id === 2) {
      options.nonWorking.dates.push(date)
      return null
    } else {
      options.dangerDay.dates.push(date)
      return;
    }
  }
  for (let i = 0; i < maxDay; i += 1) {
    let correctDate = moment(`${year}-${month}-${i+1}`, 'YYYY-MM-DD').format('YYYY-MM-DD')
    let attends = obj.attendance.filter(item => moment(item.date, 'YYYY-MM-DD').isSame(correctDate))
    let dayOfWeek = moment(`${year}-${month}-${i+1}`, 'YYYY-MM-DD').format('dddd')
    if (obj.holidays.find(item => parseInt(moment(item.date, 'YYYY-MM-DD').format('DD')) === i + 1)) {
      options.holidays.dates.push(correctDate)
      continue
    }
    if (obj.employee.is_default_schedule) {
      if (dayOfWeek === 'Saturday' || dayOfWeek === 'Sunday' && attends.length === 0) {
        options.nonWorking.dates.push(correctDate)
      } else {
        if (attends.length > 0) {
          getStatusAttendance(attends[0], { date: correctDate })
        } else {
          options.default.dates.push(correctDate)
        }
      }
    } else {
      let status = obj.employee.employee_schedule.some(el => el === dayOfWeek)
      if (obj.employee.employee_schedule.length > 0 && status) {
        if (attends.length > 0) {
          getStatusAttendance(attends[0], { date: correctDate })
        } else {
          options.default.dates.push(correctDate)
        }
      } else {
        options.nonWorking.dates.push(correctDate)
      }
    }
  }
  return Object.keys(options).map((key) => ({ key, ...options[key] }));
}
