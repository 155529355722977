<template>
<div class="profile__financial-info">
  <div class="profile__financial-info__action">
    <h5>my finances</h5>
    <b-button
        variant="primary"
        disabled
        v-if="loading"
    >
      <b-spinner small type="grow"></b-spinner>
      Loading...
    </b-button>
    <button
        class="btn btn-outline-primary btn-timer"
        @click.prevent="startTimer()"
        v-else
    >
      <img v-if="isShowInfo" src="@/assets/images/icons/ic-eye-slash.svg" alt="show-icon">
      {{ isShowInfo ? `Hide (${countTime})` : 'Show' }}
    </button>
  </div>
  <div class="profile__financial-info__item">
    <p>My salary per/m</p>
    <span>{{ isShowInfo ? `$${$convertToFloat(item?.salary ?? 0)}` : '***' }}</span>
  </div>
  <div class="profile__financial-info__item">
    <div class="d-flex align-items-center">
      <p class="w-100 mr-1">Compensations</p>
      <img
          v-if="item?.compensationsDetails?.length"
          class="cursor-pointer"
          src="@/assets/images/icons/ic-info-outline.svg"
          alt="info-icon"
          v-b-tooltip.hover="{
            html: true,
            variant: 'light',
            title: getCompensationInfo(item.compensationsDetails)
          }"
      >
    </div>
    <span
        :class="{'text-success': (item?.compensations > 0 && isShowInfo)}"
    >
      {{ isShowInfo ? `$${$convertToFloat(item?.compensations ?? 0)}` : '***' }}
    </span>
  </div>
  <div class="profile__financial-info__item" v-if="item?.overtime > 0">
    <p>Overtime</p>
    <span
        :class="{'text-success': (item?.overtime > 0 && isShowInfo)}"
    >
      {{ isShowInfo ? `$${$convertToFloat(item?.overtime ?? 0)}` : '***' }}
    </span>
  </div>
  <div class="profile__financial-info__item">
    <p>Penalties</p>
    <span
        :class="{'text-danger': (item?.penalty > 0 && isShowInfo)}"
    >
      {{ isShowInfo ? `$${$convertToFloat(item?.penalty ?? 0)}` : '***' }}
    </span>
  </div>
  <div class="profile__financial-info__item" v-if="item?.prepayments > 0">
    <p>Paid</p>
    <span
        :class="{'text-danger': (item?.prepayments > 0 && isShowInfo)}"
    >
      {{ isShowInfo ? `$${$convertToFloat(item?.prepayments ?? 0)}` : '***' }}
    </span>
  </div>
  <hr>
  <div class="profile__financial-info__total">
    <p>To be Paid:</p>
    <div class="d-flex align-items-center justify-content-between" style="width:40%;">
      <span>{{ isShowInfo ? `$${$convertToFloat(item?.total ?? 0)}` : '***' }}</span>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "ProfileCalendarPanel",
  props: {
    item: {
      type: Object
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timer: null,
      isShowInfo: false,
      countTime: 0
    }
  },
  methods: {
    getCompensationInfo(items) {
      if (items?.length) {
        return items.map(el => {
          return `<p class="text-left mb-0 font-size-12">${el.title} : <span>${this.isShowInfo ? `$${this.$convertToFloat(el.amount)}` : '***'}</span></p>`
        }).join('')
      }
    },
    setIntervalTime(sec) {
      this.countTime = sec ?? 30
      this.isShowInfo = true
      this.timer = setInterval(() => {
        if (this.countTime > 0) {
          this.countTime -= 1
        } else {
          this.isShowInfo = false
          clearInterval(this.timer)
        }
      }, 1000)
    },
    startTimer(sec) {
      if (this.isShowInfo) {
        clearInterval(this.timer)
        this.isShowInfo = !this.isShowInfo
      } else {
        this.setIntervalTime(sec)
      }
    }
  },
  beforeDestroy() {
    clearInterval(this.timer)
  }
}
</script>

<style lang="scss" scoped>
.profile__financial-info {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-left: 1px solid #F0F0F0;
  padding: 0 0 13px 20px;
  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 8px;
    .btn-timer {
      &:hover {
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(19%) hue-rotate(64deg) brightness(106%) contrast(100%);
        }
      }
    }
    h5 {
      margin-bottom: 0;
      font-size: 11px;
      font-weight: 600;
      color: #ADAFC5;
      text-transform: uppercase;
    }
  }
  &__item {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    &:nth-child(2) {
      padding-top: 16px;
    }
    &:last-child {

    }
    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      margin: 0;
      color: #ADAFC5;
      width: 60%;
    }
    span {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      margin: 0;
      text-align: left;
      width: 40%;
    }
  }
  &__total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      margin: 0;
      color: #000000;
      width: 60%;
    }
    span {
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      margin: 0;
      text-align: left;
    }
  }
  hr {
    width: 100%;
    height: 1px;
    background-color: #F0F0F0;
    border: none;
    outline: none;
    margin: 0;
  }
}
.link-item {
  font-size: 13px;
  color: #556EE6;
  font-weight: 500;
  line-height: 20px;
  text-decoration: underline !important;
}

</style>
