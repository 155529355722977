<template>
<div class="profile__personal-info">
  <div class="profile__personal-info__main">
    <div class="personal-info__photo">
      <img :src="avatar" alt="avatar">
      <label
          v-if="!preview"
          for="photo-uploader"
          class="m-0 text-center"
      >
        Change photo
        <input
            id="photo-uploader"
            type="file"
            hidden
            @change="onPhotoChange"
            accept="image/png, image/gif, image/jpeg"
        />
      </label>
      <div
          v-if="preview"
          class="personal-info__photo__action"
      >
        <button class="btn btn-transparent" @click.prevent="cancelPreview">Cancel</button>
        <button class="btn btn-transparent" @click.prevent="updatePhoto">Save</button>
      </div>
    </div>
    <p class="personal-info__name">{{ user?.employee?.name ?? '---' }}</p>
    <span class="personal-info__position">{{ user?.employee?.positions?.[0]?.title ?? '---' }}</span>
    <span class="badge badge-pill badge-primary text-uppercase">{{ user?.employee?.department?.title ?? '---' }}</span>
  </div>
  <div class="profile__personal-info__details">
    <div class="info-details__inner">
      <div class="info-detail">
        <div class="d-flex justify-content-between align-items-center pb-2">
          <h5 class="pb-0">My Contacts</h5>
          <button
              @click.prevent="$emit('updateProfile')"
              class="btn-transparent-info"
              v-b-tooltip.hover
              title="Update Personal Data"
          >
            <img src="@/assets/images/icons/ic-pencil-edit.svg" alt="">
          </button>
        </div>
        <div class="info-detail__item">
          <img src="@/assets/images/icons/ic-message.svg" alt="message">
          <p>{{ user?.employee?.email ?? '---' }}</p>
        </div>
        <div class="info-detail__item">
          <img src="@/assets/images/icons/ic-call.svg" alt="phone">
          <p>{{ user?.employee?.phone ? `${user.employee.phone}` : '---' }}</p>
        </div>
      </div>
      <div class="info-detail">
        <h5 class="text-profile-title">PROFILE</h5>
        <div class="info-detail__item">
          <button
              @click.prevent="moveToPersonalFile(user.employee.personal_file.link)"
              class="btn-transparent-info"
          >
            <i class="bx bxs-user-rectangle font-size-18"></i>
            {{ user?.employee?.personal_file?.link ? 'Personal File' : 'File Not Found' }}
          </button>
        </div>
      </div>
    </div>
    <div class="info-details__vacation">
      <div class="info-vacation__item">
        <div class="vacation-title">
          <img src="@/assets/images/icons/ic-sun.svg" alt="sun-icon">
          <p>Vacation hours</p>
        </div>
        <span>{{ user?.attendances?.available_vacation_hours ?? '---' }}</span>
      </div>
      <div class="info-vacation__item">
        <div class="vacation-title">
          <img src="@/assets/images/icons/ic-bimicoin.svg" alt="bimicoin">
          <p>BimiCoins</p>
        </div>
        <a href="#" @click.prevent="$router.push('/bimi-coin/balance-details')">{{ balance ?? 0 }}</a>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import AvatarDefault from "@/assets/images/users/avatar-default-rect.png";
export default {
  name: "ProfileInfoPanel",
  props: {
    user: {
      type: Object
    },
    balance: {
      type: [Number, String]
    }
  },
  data() {
    return {
      AvatarDefault,
      preview: null,
      fReader: new FileReader(),
      file: null
    }
  },
  methods: {
    onPhotoChange(event) {
      if (event.target.files.length) {
        const [file] = event.target.files;
        this.fReader.readAsDataURL(file);
        this.file = file
      } else {
        this.preview = null;
        this.file = null
      }
    },
    cancelPreview() {
      this.preview = null;
      this.file = null
    },
    moveToPersonalFile(link) {
      if (link) {
        return window.open(link, '_blank')
      }
    },
    updatePhoto() {
      this.preview = null
      this.$emit('updatePhoto', this.file)
    },
  },
  computed: {
    avatar() {
      if (this.preview) {
        return this.preview;
      }
      return this.user ? (this.user?.employee?.photo || AvatarDefault) : AvatarDefault
    },
  },
  created() {
    this.fReader.onloadend = () => {
      this.preview = this.fReader.result;
    }
  }
}
</script>

<style lang="scss" scoped>
.profile__personal-info {
  display: flex;
  button {
    &.btn-transparent-info {
      background-color: transparent;
      outline: none;
      border: 1px solid #F0F0F0;
      border-radius: 53px;
      padding: 0 15px;
      font-size: 13px;
      color: #292B41;
      font-weight: 500;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      white-space: nowrap;
      transition: all .2s ease;
      line-height: 36px;
      height: 36px;
      &:hover {
        opacity: .9;
      }
    }
  }
  p, h5 {
    margin-bottom: 0;
  }
  &__main, &__details {
    width: 50%;
  }
  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .personal-info__photo {
      width: 160px;
      height: 160px;
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;
      position: relative;
      &__action {
        position: absolute;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
        background: #00000033;
        height: 30px;
        button {
          color: #ffffff;
          font-size: 13px;
          padding: 0 5px;
        }
      }
      label {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 30px;
        background: #00000033;
        z-index: 1;
        color: #ffffff;
        font-size: 13px;
        font-weight: 500;
        cursor: pointer;
      }
      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
    .personal-info__name {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
      color: #292B41;
      margin-bottom: 5px;
      text-align: center;
    }
    .personal-info__position {
      font-size: 13px;
      line-height: 18px;
      color: #ADAFC5;
      margin-bottom: 10px;
      text-align: center;
    }
  }
  &__details {
    border-left: 1px solid #F0F0F0;
    .info-details__inner {
      .info-detail {
        padding: 20px;
        &:first-child {
          padding-bottom: calc(20px - .5rem);
        }
        h5 {
          font-weight: 600;
          color: #ADAFC5;
          font-size: 11px;
          line-height: 13px;
          padding-bottom: 10px;
          text-transform: uppercase;
        }
        &__item {
          display: flex;
          padding-bottom: 10px;
          &:last-child {
            padding-bottom: 0;
          }
          p {
            padding-left: 10px;
            font-size: 15px;
            color: #292B41;
            line-height: 18px;
            font-weight: 400;
            width: 100%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }
    }
    .info-details__vacation {
      .info-vacation {
        &__item {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 13px 13px 13px 15px;
          border-bottom: 1px solid #F0F0F0;
          &:first-child {
            border-top: 1px solid #F0F0F0;
            padding-top: 12px;
            padding-bottom: 12px;
          }
          &:last-child {
            border-bottom: none;
          }
          .vacation-title {
            display: flex;
            align-items: center;
            img {
              width: 20px;
              height: 20px;
            }
            p {
              font-size: 13px;
              font-weight: 500;
              color: #292B41;
              padding-left: 10px;
            }
          }
          span {
            color: #2D3338;
            font-size: 13px;
            font-weight: 500;
          }
          a {
            font-size: 13px;
            text-decoration: underline !important;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 575px) {
  .profile__personal-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__main {
      padding: 20px 0;
    }
    &__details {
      width: 100%;
      .info-details__inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .info-detail {
          width: 100%;
          padding-bottom: 10px !important;
          h5 {
            text-align: left;
          }
          &__item {
            justify-content: center;
            p {
              width: auto;
            }
          }
        }
      }
    }
  }
}
</style>
