export default {
    data() {
        return {
            uzbTime: this.$moment().utc().add(5, 'hours').format('YYYY-MM-DD HH:mm:ss'),
            timeInterval: null
        }
    },
    methods: {
        setIntervalForTimeline(sec) {
            this.timeInterval = setInterval(() => {
                this.uzbTime = this.$moment().utc().add(5, 'hours').format('YYYY-MM-DD HH:mm:ss')
            }, sec * 1000);
        },
    },
    beforeDestroy() {
        window.clearInterval(this.timeInterval)
    }
}
