<template>
  <b-list-group>
    <b-list-group-item
      v-for="(item, index) in softwares"
      :key="item.title + index"
    >
      <h5>{{ item.title }}</h5>
      <p class="mb-0">{{ item.description }}</p>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
export default {
  name: 'SoftwaresTab',
  props: ['softwares']
}
</script>
